import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./ChatLayout.css";
// import ChatHeader from "./ChatHeader";
import MobileScreen from "./MobileScreen";
import { useParams } from "react-router-dom";
import Joyride, { EVENTS, STATUS } from "react-joyride";
import useHotjar from "./useHotjar";

const ChatLayout = ({ children }) => {
  const headerRef = useRef(null);
  const isShared = window.location.pathname.includes("shared");
  const highLightLegalReviewButton = localStorage.getItem("highLightLegalReviewButton");
  const highDocumentUploader = localStorage.getItem("highDocumentUploader");
  const childRef = useRef(null);
  const isDraft = window.location.pathname.includes("draft");
  const [run, setRun] = useState(true);
  const [steps, setSteps] = useState([]);
  let { chatId } = useParams();
  useHotjar();

  useEffect(() => {
    if (isDraft && highLightLegalReviewButton) {
      setSteps([
        {
          target: ".request-legal-review",
          content: "Once you’re done chatting with AI, click here to get a human lawyer to verify the AI’s responses.",
          placement: "bottom",
          disableBeacon: true,
          hideFooter: true,
          spotlightPadding: 0,
        },
      ]);
    }
    if (!isDraft && chatId && highLightLegalReviewButton) {
      setSteps([
        {
          target: ".request-legal-review",
          content: "Once you’re done chatting with AI, click here to get a human lawyer to verify the AI’s responses.",
          placement: "bottom",
          disableBeacon: true,
          hideFooter: true,
          spotlightPadding: 0,
        },
      ]);
    }

    if (!isDraft && !chatId && highDocumentUploader) {
      setSteps([
        {
          target: ".document-upload-button",
          content: "Click here to upload a document for review",
          placement: "bottom",
          disableBeacon: true,
          hideFooter: true,
          spotlightPadding: 0,
        },
      ]);
    }
  }, [isDraft, chatId, highLightLegalReviewButton, highDocumentUploader]);

  useEffect(() => {
    if (chatId && headerRef.current && highLightLegalReviewButton) {
      setTimeout(() => {
        setRun(true);
      }, 2000);
    } else if (!isDraft && childRef.current && highDocumentUploader) {
      setRun(true);
    } else {
      setRun(false);
    }
  }, [chatId, highLightLegalReviewButton, chatId, highDocumentUploader, isDraft]);

  const handleJoyrideCallback = (data) => {
    const { status, type, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || type === EVENTS.TOUR_END) {
      setRun(false);
    }
    if (action === "close" && highDocumentUploader && !isDraft) {
      localStorage.removeItem("highDocumentUploader");
    }
    if (action === "close" && highLightLegalReviewButton && chatId) {
      localStorage.removeItem("highLightLegalReviewButton");
    }
  };
  return (
    <div className="chat-layout">
      <head>
        <title>Chat with Lexxa—Advanced Legal AI | InHouse</title>
        <meta
          name="description"
          content="How can Lexxa help you today? Ask a legal question, draft a legal document, review a legal contract. If you think it, Lexxa can do it."
        />
      </head>
      {/* <ChatHeader ref={headerRef} /> */}
      {/* <div className="chat-layout-body flex">{children}</div> */}
      <div className="chat-layout-body flex">
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, { ref: childRef });
        })}
      </div>
      {/* <MobileScreen /> */}
      {run && (
        <Joyride
          continuous
          run={run}
          steps={steps}
          callback={handleJoyrideCallback}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )}
    </div>
  );
};

ChatLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChatLayout;
