import React, { useEffect, useState } from "react";
import copy from "../../images/copy.svg";
import { ArrowBendDoubleUpRight, Briefcase, PenNib, ThumbsDown, ThumbsUp, X } from "@phosphor-icons/react";
import { useDispatch, useSelector } from "react-redux";
import {
    requestLegalReview, saveAsTemplate, acceptEditSuggestions,
    // updateSaveAsTemplateChats,
} from "../../pages/chatpage/chatSlice";
// import { legalReviewFeaturesNew } from "./ChatHeader";
import AskAvatar from "../../images/AskAvatar.svg";
import DraftAvatar from "../../images/DraftAvatar.svg";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { setRetainerModalOpen, setShowSubscriptionModal } from "../../pages/UserAuthentication/AuthenticationSlice";
import { setShowAskToDraftModal } from "../../pages/chatpage/messageSlice";


const ChatMessage = ({
    isAIReply,
    chatInfo,
    message_type,
    isLastReply,
    chatId,
    selectedThread,
    legalReviewRequested,
    subscriptionType,
    setUserAction,
    isFirstAiMessage
}) => {
    const currentDate = new Date(chatInfo?.created_at);
    const options = {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZoneName: "short",
    };

    const [animate, setAnimate] = useState(false);
    const getThreadStatus = useSelector((state) => state.chat.getThreadStatus);
    // const [retainermodalOpen, setRetainerModalOpen] = useState(false);
    const [reviewRequest, setReviewRequest] = useState("");
    const [promoCode, setPromoCode] = useState("");
    const [buttonsToShow, setButtonsToShow] = useState([]);
    const [showLegalReviewRequestButton, setShowLegalReviewRequestButton] = useState(true);
    const [showTemplateButton, setShowTemplateButton] = useState(true);
    const [showHyperLinkButton, setShowHyperLinkButton] = useState(true);
    const [threadType, setThreadType] = useState(null);
    const [avatar, setAvatar] = useState(null);

    const dispatch = useDispatch();

    const templateSaveStatus = useSelector((state) => state.chat.templateSaveStatus);

    const changesAccepted = useSelector((state) => state.chat.changesAccepted);
    const lastConversationalMessage = useSelector((state) => state.chat.lastConversationalMessage);
    const saveAsTemplateChats = useSelector((state) => state.chat.saveAsTemplateChats);
    const legalReviewRequestedChats = useSelector((state) => state.chat.legalReviewRequestedChats);
    const viewChatLinks = useSelector((state) => state.chat.viewChatLinks);

    useEffect(() => {
        if (message_type === "ask_message") {
            setThreadType("ask");
            setAvatar(AskAvatar);
        }
        else if (message_type === "draft_message") {
            setThreadType("draft");
            setAvatar(DraftAvatar);
        }
    }, [message_type]);

    useEffect(() => {
        setShowHyperLinkButton(viewChatLinks[viewChatLinks.length - 1] === chatInfo.id)
        updatebuttonsToShow();
    }, [viewChatLinks]); // eslint-disable-line

    useEffect(() => {
        setShowTemplateButton(saveAsTemplateChats[saveAsTemplateChats.length - 1] === chatInfo.id)
        updatebuttonsToShow();
    }, [saveAsTemplateChats]); // eslint-disable-line

    useEffect(() => {
        var show = legalReviewRequestedChats[legalReviewRequestedChats.length - 1] === chatInfo.id;
        setShowLegalReviewRequestButton(show)
        updatebuttonsToShow();
    }, [legalReviewRequestedChats]); // eslint-disable-line


    useEffect(() => {
        const newAiMessage = localStorage.getItem("newAiMessage");
        if (newAiMessage && newAiMessage === chatInfo.id && isAIReply) {
            setAnimate(true);
            localStorage.removeItem("newAiMessage");
        }
    }, [getThreadStatus]); // eslint-disable-line


    const legalReviewAction = () => {
        // if user is based in california, 
        // if subscribed, set retainer modal
        // else show subscription modal

        // if user is based oôutside california,
        // set retainer modal true
        // if (user?.state === "California") {
        //     if (["free", null, "", "Free-Plan-USD-Monthly", "trial_expired", "subscription_expired", "trial"].indexOf(subscriptionType) !== -1) {
        //         setUserAction("legal-review-request");
        //         dispatch(setShowSubscriptionModal(true))
        //         window.analytics.track("subscription popup triggered", { "action": "legal review request", "chatId": chatId, "user_state": user?.state, user_email: user?.user_email });
        //     }
        //     else {
        //         dispatch(setRetainerModalOpen(true))
        //         window.analytics.track("retainer popup triggered", { "action": "legal review request", "chatId": chatId, "user_state": user?.state, user_email: user?.user_email });

        //     }
        // }
        // else {
        //     dispatch(setRetainerModalOpen(true))
        //     window.analytics.track("retainer popup triggered", { "action": "legal review request", "chatId": chatId, "user_state": user?.state, user_email: user?.user_email });
        // }
    }

    const createLegalReviewRequest = () => {
        dispatch(requestLegalReview({ threadId: chatId, reviewRequest: reviewRequest, promoCode: promoCode }));
    };



    const formatText = (text) => {
        text = text.replace(/`/g, "&#96;");
        text = text.replace(/\\n/g, ' \n ');
        text = text.replace(/\n#/g, ' \n \n #');
        text = text.replace(/\n/g, ' \n\n ');
        text = text.replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
        text = text.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
        return text;
    }

    const hasAcceptEditSuggestionsButton = () => {
        if (chatInfo?.quick_action_buttons?.length > 0) {
            return chatInfo?.quick_action_buttons.some((btn) => btn.type === "accept_edit_suggestions");
        }
        return false;
    }
    const showQuickActionButton = (btn) => {
        if (btn.type === "accept_edit_suggestions" || btn.type === "quick_response") {
            if (isLastReply) {
                return true;
            }
            return false;
        }
        if (btn.type === "legal_review_request") {
            if (showLegalReviewRequestButton) {
                if (legalReviewRequested) {
                    return false;
                }
                // if this is the last conversational message, then show the legal review button
                if (showLegalReviewRequestButton) {
                    return true;
                }
                return false
            }
            else {
                return false;
            }
        }
        if (btn.type === "hyperlink" && showHyperLinkButton) {
            return true
        }
        if (btn.type === "save_as_template") {
            if (selectedThread?.template) {
                return false
            }
            if (showTemplateButton) {
                if (templateSaveStatus !== "success") {
                    return true;
                }
            }
        }

        if (isLastReply || lastConversationalMessage === chatInfo.id) {
            if (changesAccepted) {
                return true;
            }
            if (hasAcceptEditSuggestionsButton()) {
                return false;
            }
            return true;
        }
    }

    const updatebuttonsToShow = () => {
        // debugger;
        if (chatInfo?.quick_action_buttons?.length > 0) {
            var buttons = [];
            chatInfo?.quick_action_buttons.map((btn) => {
                if (showQuickActionButton(btn)) {
                    buttons.push(btn)
                }
            })
            setButtonsToShow(buttons)
            return [];
        }
    }

    useEffect(() => {
        updatebuttonsToShow()
    }, []);




    return (
        <div className="flex flex-col w-full px-0">
            <div className="flex flex-col w-[100%]">
                {chatInfo?.text &&
                    <div className={`flex rounded-b-[5px] 
                   ${chatInfo?.is_user_message ? "text-black font-[500] text-[24px] leading-[33.6px]" : "text-[16px] leading-[25.6px] text-[#333333]"}`
                    }>
                        <div className="chat">
                            {isFirstAiMessage && (<img src={avatar} alt="AI" className="w-[32px] h-[32px] mb-[16px]" />)}
                            <Markdown remarkPlugins={[remarkGfm]}>{`${formatText(chatInfo?.text)}`}</Markdown>
                        </div>
                    </div>
                }
            </div>
            {chatInfo?.quick_action_buttons?.length > 0 && (
                <div className="flex flex-col my-[12px] gap-[16px]">
                    <hr />
                    <div className="flex flex-row gap-[8px]">
                        <ArrowBendDoubleUpRight size={20} className="text-[#949494] scale-y-[-1]" />
                        <span className="text-[16px] text-[#949494] leading-[25.6px] font-[500]">Suggested Actions</span>
                    </div>
                    {chatInfo?.quick_action_buttons.map((btn, index) => (
                        <>
                            {
                                btn.type === "legal_review_request" && (
                                    <button
                                        key={index}
                                        className="cursor-pointer flex flex-row w-fit p-[12px] text-[#FFF] rounded-[8px] border border-[#E0E0E0] bg-[#18475A] text-[14px] leading-[19.6px] font-[500] gap-[8px]"
                                        onClick={legalReviewAction}
                                    >
                                        <Briefcase size={16} className="text-white my-auto" />
                                        <span className="my-auto">{btn.text}</span>
                                    </button>
                                )
                            }
                            {
                                btn.type === "draft" && (
                                    <button
                                        key={index}
                                        onClick={() => { dispatch(setShowAskToDraftModal(true)) }}
                                        className="cursor-pointer flex flex-row w-fit p-[12px] bg-[#FFFFFF] rounded-[8px] border border-[#D1D1D1] text-[#264653] text-[14px] leading-[19.6px] font-[500] gap-[8px]"
                                    >
                                        <PenNib size={16} className="text-[#13866F] my-auto" />
                                        <span className="my-auto">{btn.text}</span>
                                    </button>
                                )
                            }
                        </>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ChatMessage;
