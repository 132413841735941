import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./Signin.css";

import { SignIn, SignUp } from "@clerk/clerk-react";

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [notice, setNotice] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [emailVerificationRequired, setEmailVerificationRequired] = useState(false);
  const [code, setCode] = useState();

  const authError = useSelector((state) => state.auth.signuperror);
  // const userType = useSelector(state => state.auth.userType);
  const user = useSelector((state) => state.auth.user);
  const disabled = useSelector((state) => state.auth.status === "loading");
  const signupStatus = useSelector((state) => state.auth.status);
  const signinStatus = useSelector((state) => state.auth.signupStatus);

  const urlParams = new URLSearchParams(window.location.search);


  useEffect(() => {
    if (authError) {
      setNotice(authError.message);
      setShowLoading(false);
    } else {
      setNotice("");
    }
  }, [authError]);

  if (process.env.REACT_APP_POST_SIGNOUT_URL !== "/signin") {
    window.location.href = "https://www.inhouse.app/auth";
  }

  const [activeTab, setActiveTab] = useState('signin');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };


  return (
    <div className="px-auto mx-auto align-items-center items-center w-[400px] mt-[100px]">
      <div className="text-sm text-center text-gray border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px  text-[20px]">
          <li className="me-2 cursor-pointer" onClick={() => handleTabClick('signin')} >
            <span className={`inline-block p-4 border-b-2 rounded-t-lg ` + (activeTab === 'signin' ? "text-blue  border-blue-600  active dark:text-blue dark:border-blue-500" : "hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300")}>Sign In</span>
          </li>
          <li className="me-2 cursor-pointer" onClick={() => handleTabClick('signup')} >
            <span className={`inline-block p-4 border-b-2 rounded-t-lg ` + (activeTab === 'signup' ? "text-blue  border-blue-600  active dark:text-blue dark:border-blue-500" : "hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300")}>Sign up</span>
          </li>
        </ul>
      </div>
      <div className="tab-content">
        {activeTab === 'signin' ?
          <SignIn routing="virtual"
            // forceRedirectUrl="https://chat.inhouse.so/onboarding"
            fallbackRedirectUrl="/?utm_source=signin"
            // signUpForceRedirectUrl="https://chat.inhouse.so/onboarding"
            signUpFallbackRedirectUrl="/onboarding?utm_source=signup"
            signUpUrl="/auth"
          /> :
          <SignUp routing="virtual"
            // forceRedirectUrl="https://chat.inhouse.so/onboarding"
            fallbackRedirectUrl="/onboarding?utm_source=signup"
            // signInForceRedirectUrl="https://chat.inhouse.so/onboarding"
            signInFallbackRedirectUrl="/onboarding?utm_source=signup"
            signInUrl="/signin"
          />}
      </div>
    </div>

  );
};

export default Signin;
