// import logo from './logo.svg';
import "./App.css";
import React, { useEffect, useReducer, useMemo } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// import { RequireAuth } from "./persistence/middlewares/AuthMiddleware";
// import ChatPage from "./pages/chatpage/ChatComponent";
import { Provider } from "react-redux";
import { ClerkProvider } from '@clerk/clerk-react'
import RootStore from "./persistence/stores/RootStore";

import Homepage from "./pages/Homepage/Home";

import AskPage from "./pages/chatpage/AskPage";

import OnboardingPage from "./pages/onboarding/OnboardingPage";

import NotFoundPage from "./pages/chatpage/NotFoundPage";
import Shared from "./pages/chatpage/Shared";
import Templates from "./pages/Templates";

import UserProfilePage from "./pages/account/UserProfile";
import DocumentViewer from "./components/DocumentViewer/index";

import ChatPageComponent from "./pages/chatpage/ChatPageComponent";
import Signin from "./pages/UserAuthentication/Signin";
import { WebSocketProvider } from './WebsocketProvider.js';
import LawyerHub from "./pages/LawyerHub";


function App() {

  const PUBLISHABLE_KEY = process.env.REACT_APP_VITE_CLERK_PUBLISHABLE_KEY;

  return (
    <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl={process.env.REACT_APP_POST_SIGNOUT_URL}>
      <Provider store={RootStore}>
        {/* <WebSocketProvider url={process.env.REACT_APP_WEBSOCKET_URL}> */}
        <WebSocketProvider>
          <Router>
            <Routes>
              <Route path="/document/:document-id" element={<DocumentViewer />} />

              <Route
                path="/account"
                element={
                  <UserProfilePage />
                }
              />
              <Route
                path="/home"
                element={
                  <Navigate to="/ask" />
                }
              />
              <Route
                path="/signin"
                element={
                  // <RequireAuth>
                  <Signin />
                  // </RequireAuth>
                }
              />
              <Route
                path="/"
                element={
                  <Homepage />
                }
              />
              <Route
                path="/template"
                element={
                  <Templates />
                }
              />
              <Route
                path="/ask"
                element={
                  <AskPage />
                }
              />

              <Route
                path="/draft"
                element={
                  <ChatPageComponent />
                }
              />
              <Route
                path="/:threadType/:chatId"
                element={
                  <ChatPageComponent />
                }
              />
              <Route
                path="/onboarding/complete"
                element={
                  <Navigate to="/update-profile#/update-profile" />
                }
              />
              <Route
                path="/onboarding"
                element={
                  <OnboardingPage />
                }
              />
              <Route
                path="/update-profile"
                element={
                  <UserProfilePage />
                }
              />

              <Route
                path="/not-found"
                element={
                  <NotFoundPage />
                }
              />
              <Route
                path="/shared/:chatId"
                element={
                  <Shared />
                }
              />
              <Route
                path="/lawyer-hub"
                element={
                  <LawyerHub />
                }
              />
              <Route path="*" element={<Navigate to="/not-found" />} />
            </Routes>
          </Router>
        </WebSocketProvider>
      </Provider>
    </ClerkProvider>
  );
}

export default App;
