export const homepageSuggestions = [
    {
        id: 1,
        type: "draft",
        line1: "Draft a contractor agreement",
        prompt: "Draft a contractor agreement",
    },
    {
        id: 2,
        type: "ask",
        line1: "What do I need to worry about when terminating an employee?",
        line2: "",
        prompt: "What do I need to worry about when terminating an employee?",
    },
    {
        id: 1,
        type: "draft",
        line1: "Draft an employee agreement",
        prompt: "Draft an employee agreement",
    },
    {
        id: 1,
        type: "review",
        line1: "Review my Employee Handbook",
        prompt: "Review my Employee Handbook",
    },
    {
        id: 1,
        type: "draft",
        line1: "Draft a supplier agreement",
        line2: "",
        prompt: "Draft a supplier agreement",
    }
]

export const allSuggestions = {
    "suggested": {
        "logo": "",
        "text": "Suggested",
        "suggestions": [
            {
                id: 1,
                type: "ask",
                line1: "How do I terminate an employee without exposing my business to liability?",
                line2: "",
                prompt: "How do I terminate an employee without exposing my business to liability?",
            },
            {
                id: 1,
                type: "review",
                line1: "How do I protect my business's intellectual property and trade secrets from employees?",
                line2: "",
                prompt: "Draft a contractor agreement",
            },
            {
                id: 1,
                type: "draft",
                line1: "Draft a",
                line2: "contractor agreement",
                prompt: "Draft a contractor agreement",
            }
        ],
    },
    "compliance": {
        "logo": "",
        "text": "Compliance",
        "suggestions": [
            {
                id: 1,
                type: "draft",
                line1: "Draft a",
                line2: "contractor agreement",
                prompt: "Draft a contractor agreement",
            },
            {
                id: 1,
                type: "review",
                line1: "Draft a",
                line2: "contractor agreement",
                prompt: "Draft a contractor agreement",
            },
            {
                id: 1,
                type: "draft",
                line1: "Draft a",
                line2: "contractor agreement",
                prompt: "Draft a contractor agreement",
            }
        ],
    },
    "employment": {
        "logo": "",
        "text": "Employment",
        "suggestions": [
            {
                id: 1,
                type: "draft",
                line1: "Draft a",
                line2: "contractor agreement",
                prompt: "Draft a contractor agreement",
            },
            {
                id: 1,
                type: "review",
                line1: "Draft a",
                line2: "contractor agreement",
                prompt: "Draft a contractor agreement",
            },
            {
                id: 1,
                type: "draft",
                line1: "Draft a",
                line2: "contractor agreement",
                prompt: "Draft a contractor agreement",
            }
        ],
    },
}

export const askHomepageSuggestions = [
    {
        "title": "Intellectual Property",
        "paragraph": "How do I trademark my cupcake business name and logo?",
        "prompt": "How do I trademark my  cupcake business name and logo?"
    },
    {
        "title": "GENERAL LEGAL AID",
        "paragraph": "What tax considerations should I know for my cupcake business?",
        "prompt": "What tax considerations should I know for my cupcake business?"
    },
    {
        "title": "Licenses and Permits",
        "paragraph": "What specific licenses do I need to run a cupcake business in Alabama?",
        "prompt": "What specific licenses do I need to run a cupcake business in Alabama?"
    }
]

export const questions = [
    {
        "text": "What is your primary reason for considering a liquor license?",
        "hyperlink_url": null,
        "confirmation_popup_text": null
    },
    {
        "id": "01c9ff25-8468-4617-bc04-38ab9d7794a1",
        "text": "What types of events will you be catering off-premise?",
        "confirmation_popup_text": null
    },
    {
        "id": "01c9ff25-8468-4617-bc04-38ab9d7794a1",
        "text": "How often do you anticipate requiring the use of a liquor license for your catering services?",
        "confirmation_popup_text": null
    }
]

export const messages = [
    {
        "id": "01c9ff25-8468-4617-bc04-38ab9d7794a0",
        "sequence_number": 1,
        "feedback_type": null,
        "message_type": "information_gathered_prompt",
        "text": "Successfully gathered information for document generation",
        "user": null,
        "created_at": "2024-09-04T06:10:45.138779Z",
        "quick_action_buttons": [
            {
                "type": "view_collected_data",
                "text": "View Data",
                "hyperlink_url": null,
                "confirmation_popup_text": null
            }
        ],
        "intent_state": null,
        "attachment_names": null
    },
    {
        "id": "01c9ff25-8468-4617-bc04-38ab9d7794a0",
        "sequence_number": 2,
        "feedback_type": null,
        "message_type": "conversation",
        "text": "Draft a Privacy Policy for my internet business Inhouse. I want to ensure that the policy is compliant with data protection laws.",
        "user": {
            "user_id": "0ed371fd-e698-4148-91ce-9b31b505b4a6",
            "first_name": "Pulkit",
            "last_name": "Pahwa",
            "user_type": "client"
        },
        "created_at": "2024-09-04T06:10:45.138779Z",
        "quick_action_buttons": [
            {
                "type": "legal_review_request",
                "text": "Talk to a lawyer",
                "hyperlink_url": null,
                "confirmation_popup_text": null
            }
        ],
        "intent_state": null,
        "attachment_names": null
    },
    {
        "id": "da9e7396-3ce0-4c23-a406-70704706ac16",
        "sequence_number": 3,
        "feedback_type": null,
        "message_type": "conversation",
        "text": "Before I can draft the Privacy Policy for INhouse, I'll need to ask you a few questions to ensure the document is tailored to your business and complies with relevant laws. Please provide answers to the following:\n\n1. What type of information will you collect from users of your website?\n2. How will you use the information collected from users?\n3. Under what circumstances will you share user information with third parties?\n4. What measures are you putting in place to protect user information?\n5. What cookies and tracking technologies will you use on your website?\n6. What is the official name of your business?\n7. Who should users contact if they have questions about the Privacy Policy?\n8. How will users be informed of changes to the Privacy Policy?\n9. What rights do users have regarding their personal information?\n\nYour answers to these questions will help in creating a comprehensive and accurate Privacy Policy for INhouse.",
        "user": null,
        "created_at": "2024-09-04T06:11:04.929963Z",
        "quick_action_buttons": [
            {
                "type": "quick_response",
                "text": "Skip these questions",
                "hyperlink_url": null,
                "confirmation_popup_text": "Are you sure you want to stop asking questions? The answers will help us tailor the draft for your requirements. I will leave placeholders in the contract for you to fill later."
            },
            {
                "type": "legal_review_request",
                "text": "Talk to a lawyer",
                "hyperlink_url": null,
                "confirmation_popup_text": null
            }
        ],
        "intent_state": "asking_questions",
        "attachment_names": null
    },
    {
        "id": "01c9ff25-8468-4617-bc04-38ab9d7794a1",
        "sequence_number": 4,
        "feedback_type": null,
        "message_type": "questions",
        "text": "Successfully gathered information for document generation",
        "user": null,
        "created_at": "2024-09-04T06:10:45.138779Z",
        "questions": questions,
        "intent_state": null,
        "attachment_names": null
    },
    {
        "id": "01c9ff25-8468-4617-bc04-38ab9d7794a1",
        "sequence_number": 4,
        "feedback_type": null,
        "message_type": "document_generated",
        "text": "Successfully gathered information for document generation",
        "user": null,
        "created_at": "2024-09-04T06:10:45.138779Z",
        "questions": [
            {
                "type": "view_collected_data",
                "text": "View Data",
                "hyperlink_url": null,
                "confirmation_popup_text": null
            }
        ],
        "intent_state": null,
        "attachment_names": null
    },
    {
        "id": "01c9ff25-8468-4617-bc04-38ab9d7794a1",
        "sequence_number": 5,
        "feedback_type": null,
        "message_type": "information",
        "text": "Successfully gathered information for document generation",
        "user": null,
        "created_at": "2024-09-04T06:10:45.138779Z",
        "questions": [
            {
                "id": "01c9ff25-8468-4617-bc04-38ab9d7794a1",
                "text": "View Data",
                "confirmation_popup_text": null
            },
        ],
        "intent_state": null,
        "attachment_names": null
    },
]

export const documentTypeSuggestions = [
    {
        id: 1,
        type: "draft",
        min_parties: 2,
        max_parties: 3,
        title: "Contractor agreement",
    },
    {
        id: 2,
        type: "draft",
        min_parties: 1,
        max_parties: 1,
        title: "Privacy Policy",
    },
    {
        id: 3,
        type: "draft",
        min_parties: 1,
        max_parties: 1,
        title: "Terms of Service",
    }
]

export const selectedDocumentType = {
    id: 1,
    type: "draft",
    min_parties: 1,
    max_parties: 3,
    title: "Contractor agreement",
}

export const partiesList = [
    // {
    //     id: 1,
    //     name: "Acme Innovations Inc.",
    //     state: "Delaware",
    //     incorporation_type: "LLC",
    //     address: "123 Main Street, Suite 200, San Francisco, CA 94105",
    //     notes: "This is a note about Acme Innovations Inc.",
    //     type: "company"
    // }
]

export const selectedParties = [
    // {
    //     id: 1,
    //     name: "Acme Innovations Inc.",
    //     state: "Delaware",
    //     incorporation_type: "LLC",
    //     address: "123 Main Street, Suite 200, San Francisco, CA 94105",
    //     notes: "This is a note about Acme Innovations Inc.",
    //     type: "company"
    // }
]

function getDummyThreadEntry() {
    return {
        id: Math.random().toString(36).substring(7),
        title: "Draft " + Math.random().toString(36).substring(7),
        messagingDisabled: Math.random() < 0.5,
        type: Math.random() < 0.5 ? "draft" : "review",
        is_shared: Math.random() < 0.5,
        document_storage_id: Math.random().toString(36).substring(7),
    }
}

export const threads = {
    "today": [
        getDummyThreadEntry(),
        // getDummyThreadEntry(),
        // getDummyThreadEntry(),
        // getDummyThreadEntry(),
        // getDummyThreadEntry(),
    ],
    "yesterday": [
        getDummyThreadEntry(),
        // getDummyThreadEntry(),
        // getDummyThreadEntry(),
        // getDummyThreadEntry(),
        // getDummyThreadEntry(),
    ],
    "last_week": [
        getDummyThreadEntry(),
        // getDummyThreadEntry(),
        // getDummyThreadEntry(),
        // getDummyThreadEntry(),
        // getDummyThreadEntry(),
    ],
    "last_month": [
        getDummyThreadEntry(),
        // getDummyThreadEntry(),
        // getDummyThreadEntry(),
        // getDummyThreadEntry(),
        // getDummyThreadEntry(),
    ],
}

export const savedPartiesForDraft = [
    {
        id: 1,
        name: "Acme Innovations Inc.",
        state: "Delaware",
        incorporation_type: "LLC",
        address: "123 Main Street, Suite 200, San Francisco, CA 94105",
        notes: "This is a note about Acme Innovations Inc.",
        type: "company"
    },
    {
        id: 2,
        name: "Pulkit Pahwa",
        state: "California",
        // incorporation_type: "LLC",
        address: "123 Main Street, Suite 200, CA 94105",
        notes: "This is a note about Acme Innovations Inc.",
        type: "individual"
    },
]


export const defaultThreadData = {
    "id": null,
    "messages": [],
    "messagingDisabled": true,
    "thread_type": "draft",
    "title": null,
    "title_source": null,
    "legal_review_requested": null,
    "questions": {
        "status": "not_asked", // pending, completed
        "questions": questions,
        "active_index": 0,
        "answers": {}, // {question_index: response}
        "skipped": [] // [indx1, indx2]
    },
    "document": {
        "id": null,
        "name": null,
        "url": null,
        "version": null,
        "placeholders": {
            "data": [],
            "activePlaceholder": 0,
            "dismissedPlaceholders": [],
            "answeredPlaceholders": []
        },
        "key_clauses": {},
        "assumptions": {},

    },
}