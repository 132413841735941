import { ArrowUp, Info, Paperclip, Stop } from '@phosphor-icons/react';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import applicationStore from "../../../persistence/stores/RootStore.js"

const ChatBoxComponent = ({ size, allowAttachReference = false, sentences = null,
    inputMessage, setInputMessage, onsubmit
}) => {
    // size can have two values: half or full depending on whether it's a split screen or full screen
    const messagingDisabled = useSelector(state => state.chat.messagingDisabled);
    const chatInputMessage = useSelector(state => state.messageSlice.chatInputMessage);
    const newThreadMessage = useSelector(state => state.messageSlice.newThreadMessage);

    if (!sentences) {
        sentences = [
            "Placeholder sentence 1",
            "Placeholder sentence 2",
            "Placeholder sentence 3",
            "Placeholder sentence 4",
            "Placeholder sentence 5"
        ];
    }
    useEffect(() => {
        if (chatInputMessage === "") {
            setInputMessage("")
        }
    }, [chatInputMessage]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (newThreadMessage === "") {
            setInputMessage("")
        }
    }, [newThreadMessage]) // eslint-disable-line react-hooks/exhaustive-deps

    const [activeSentenceIndex, setActiveSentenceIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveSentenceIndex((prevIndex) => (prevIndex + 1) % sentences.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const [typedText, setTypedText] = useState('');

    useEffect(() => {
        const activeSentence = sentences[activeSentenceIndex];

        let currentText = '';
        let currentIndex = 0;

        const typingInterval = setInterval(() => {
            const messagingDisabled = applicationStore.getState().chat.messagingDisabled;
            if (messagingDisabled) {
                setTypedText("Messaging is disabled");
            }
            else {
                if (currentIndex < activeSentence.length) {
                    currentText += activeSentence[currentIndex];
                    setTypedText(currentText);
                    currentIndex++;
                } else {
                    clearInterval(typingInterval);
                }
            }
        }, 70);

        return () => clearInterval(typingInterval);
    }, [activeSentenceIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${size === 'half' ?
            `chatbox-half ${allowAttachReference ? "h-[170px]" : "h-[154px]"}` :
            `chatbox-full  ${allowAttachReference ? "h-[150px]" : "h-[140px]"}`} 
                group flex flex-col gap-[10px] py-[16px] `}>
            <div className={`flex ${allowAttachReference ? "flex-col" : ""} items-start space-x-2 input-group-area group w-full gap-[12px] py-[16px] pl-[16px] pr-[24px] border focus-within:!border-black bg-white rounded-[16px]`}>
                <TextArea autoSize={{
                    minRows: 1,
                    maxRows: 6,
                }}
                    //  rows={5}
                    value={inputMessage}
                    disabled={messagingDisabled}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyDown={(e) => {
                        if (
                            e.key === "Enter" &&
                            (e.ctrlKey || e.metaKey) &&
                            !e.shiftKey &&
                            (e.target.tagName !== "TEXTAREA" || document.activeElement === e.target)
                        ) {
                            onsubmit()
                        }
                    }}

                    className="focus:!border-0 focus:!shadow-none form-control border-0 disabled:bg-white disabled:!border-0 disabled:!shadow-none placeholder-[#949494]"
                    placeholder={typedText} />
                <div className={`${allowAttachReference ? 'mt-[12px] flex-row w-full justify-between' : 'self-end'} button-box align-bottom flex  gap-[12px]`}>
                    {
                        allowAttachReference &&
                        <button className="border-[#D1D1D1] border bg-[#FAFAFA] gap-[4px] rounded-[11px] w-[155px] h-[40px] flex py-[8px] pl-[8px] pr-[12px] "
                        >
                            <Paperclip className='text-[#011513] my-auto' size={18} />
                            <span className='text-[#011513] text-[14px] my-auto'>Attach reference</span>
                        </button>
                    }
                    <button className={`bg-[#000] disabled:bg-[#949494]  rounded-[8px] w-[40px] h-[40px]`} disabled={messagingDisabled || inputMessage?.length === 0}>
                        {messagingDisabled ?
                            <Stop size={18} className='bg-white text-white mx-auto' />
                            : <ArrowUp size={24} className='text-white mx-auto' onClick={onsubmit} />
                        }
                    </button>
                </div>
            </div>
            {size === "half" &&
                <p className="flex flex-row text-[14px] leading-[19.6px] font-[500] text-[#7F8C8D] gap-[10px] justify-center">
                    <Info className='text-[#7F8C8D] my-[2px]' size={16} />
                    <span>AI can make mistakes. Double-check AI details and consult an attorney if needed.
                        Check our <a href="https://storage.googleapis.com/inhouse-public-documents/InHouse%20Privacy%20Policy.pdf" target="blank" className="underline"> Privacy Policy</a> for more</span>
                </p>
            }
            {size === "full" &&
                <p className="flex flex-row text-[14px] leading-[19.6px] font-[500] text-[#7F8C8D] gap-[10px] justify-center">
                    <Info className='text-[#7F8C8D] my-auto' size={12} />
                    <span>AI can make mistakes. Double-check AI details and consult an attorney if needed.
                        Check our <a href="https://storage.googleapis.com/inhouse-public-documents/InHouse%20Privacy%20Policy.pdf" target="blank" className="underline"> Privacy Policy</a> for more</span>
                </p>
            }
        </div >
    );
};

export default ChatBoxComponent;