export const stateOptions = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const companyExistance = [
  { label: "Not Started", value: "not-started" },
  { label: "0 - 2 years", value: "0-2" },
  { label: "2 - 10 years", value: "2-10" },
  { label: "10+ years", value: "10+ years" },
];

export const numberOfEmployees = [
  { label: "0", value: "0" },
  { label: "1-10", value: "1-10" },
  { label: "11-50", value: "11-50" },
  { label: "50+", value: "50+" },
  // { label: "201-500", value: "201-500" },
  // { label: "501-1000", value: "501-1000" },
  // { label: "1000+", value: "1000+" },
];

export const legalWorkOption = [
  { label: "Self-help", value: "Self-help" },
  { label: "Online Legal Services", value: "Online Legal Services" },
  { label: "Outside Counsel", value: "Outside Counsel" },
  { label: "Inside Counel", value: "Inside Counel" },
  { label: "Other", value: "Other" },
];

export const legalWorkTypePeriod = [
  { label: "Weekly", value: "Weekly" },
  { label: "Monthly", value: "Monthly" },
  { label: "Quarterly", value: "Quarterly" },
  { label: "Annually", value: "Annually" },
  { label: "Other", value: "Other" },
];

export const legalWorkTypes = [
  { label: "Employment/HR", value: "employment/HR" },
  { label: "Drafting Contracts", value: "drafting contracts" },
  { label: "Reviewing Contracts", value: "reviewing contracts" },
  { label: "Litigation", value: "litigation" },
  // { label: "Intellectual Property", value: "Intellectual Property" },
  // { label: "Licensing/Regulations", value: "Licensing/Regulations" },
  { label: "Other", value: "Other" },
];

export const annualLegalEXpenses = [
  { label: "Self Help", value: "Self Help" },
  { label: "under $5K", value: "under $5K" },
  { label: "$5K - $25K", value: "$5K - $25K" },
  { label: "over $25K", value: "over $25K" },
  // { label: "$500k-$2M", value: "$500k-$2M" },
  // { label: "Over $2M", value: "Over $2M" },
];

export const industryArray = [
  { label: "Technology", value: "Technology" },
  { label: "Retail", value: "Retail" },
  { label: "Agency", value: "Agency" },
  { label: "Consulting", value: "Consulting" },
  { label: "E-Commerce", value: "E-Commerce" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Manufacturing ", value: "Manufacturing " },
  { label: "Professional Services", value: "Professional Services" },
  { label: "Other", value: "Other" },
];

// export const entityOptions = [
//   { value: "c_corporation", label: "C-corporation" },
//   { value: "llc", label: "LLC" },
//   { value: "s_corporation", label: "S-corporation" },
//   { value: "partnership", label: "Partnership" },
//   { value: "sole_proprietorship", label: "Sole Proprietorship" },
//   { value: "non_profit", label: "Non Profit" },
//   { value: "other", label: "Other" },
// ];
export const entityOptions = {
  individual: "Individual",
  corporation: "Corporation",
  llc: "LLC",
  partnership: "Partnership",
  sole_proprietorship: "Sole Proprietorship",
  non_profit: "Non Profit",
  other: "Other",
}