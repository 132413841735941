import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setWsInputBox } from '../../../pages/Homepage/HomeSlice.js';
import TextArea from 'antd/es/input/TextArea.js';
// import { ChatsCircle, DotsThree, DownloadSimple, FileMagnifyingGlass, Funnel, MagnifyingGlass, PencilSimpleLine, PenNib, ShareFat, Trash } from '@phosphor-icons/react';
import { addMessage } from '../messageSlice.js';
import { useWebSocketContext } from '../../../WebsocketProvider.js';

const WSInputBox = () => {
    const dispatch = useDispatch();

    const [showChatHistory, setShowChatHistory] = useState(false);
    const [inputMessage, setInputMessage] = useState('');
    const viewChatHistory = useSelector((state) => state.homeslice.showWsInputBox);

    const { isConnected, messages, sendMessage } = useWebSocketContext();

    const wsinputref = useRef(null);

    const handleClickOutside = (event) => {
        if (wsinputref.current && !wsinputref.current.contains(event.target)) {
            closeChatHistory();
        }
    };

    const handleSendMessage = () => {
        sendMessage(inputMessage); // Send message through WebSocket
        setInputMessage('');
    };

    const closeChatHistory = () => {
        dispatch(setWsInputBox(false));
    }

    useEffect(() => {
        setShowChatHistory(viewChatHistory);
    }, [viewChatHistory]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (showChatHistory) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showChatHistory]); // eslint-disable-line react-hooks/exhaustive-deps

    const addMessageToDb = () => {
        if (inputMessage) {
            try {
                const inputMessageObj = JSON.parse(inputMessage);
                if (Object.keys(inputMessageObj) && Object.keys(inputMessageObj) == ["id", "sequence_number", "feedback_type", "message_type", "text", "user", "created_at", "quick_action_buttons", "intent_state", "attachment_names"]) {  // eslint-disable-line
                    dispatch(addMessage(inputMessageObj));
                    setInputMessage('');
                }
                else {
                    alert("Invalid JSON message");
                }
            }
            catch (e) {
                alert("Invalid JSON message");
            };
        }
        else {
            alert("Invalid JSON message");
        }
    }
    const sendWSRequest = () => {
        handleSendMessage(inputMessage)
        // if (inputMessage) {
        //     try {
        //         const inputMessageObj = JSON.parse(inputMessage);
        //         if (Object.keys(inputMessageObj) && Object.keys(inputMessageObj) == ["id", "sequence_number", "feedback_type", "message_type", "text", "user", "created_at", "quick_action_buttons", "intent_state", "attachment_names"]) {  // eslint-disable-line
        //             dispatch(addMessage(inputMessageObj));
        //             setInputMessage('');
        //         }
        //         else {
        //             alert("Invalid JSON message");
        //         }
        //     }
        //     catch (e) {
        //         alert("Invalid JSON message");
        //     };
        // }
        // else {
        //     alert("Invalid JSON message");
        // }
    }
    return (
        <>
            {showChatHistory && (

                <div className="absolute" >
                    <div className="chat-history flex flex-col h-screen w-[464px] shadow-l-[0px] shadow z-50 relative left-[65px] bg-white pr-[16px] pl-[24px] py-[24px] gap-[40px]" ref={wsinputref}
                    >
                        <div className="flex flex-col h-[77px] w-full gap-[10px]">
                            <div className="flex flex-row justify-between items-center bg-white">
                                <h2 className="text-[16px] font-bold leading-[12px]">Your History</h2>
                                <button onClick={() => {
                                    closeChatHistory();
                                }} className="text-red-500">Close</button>
                            </div>
                            <div className="input-group-area flex flex-col gap-[12px] px-[12px] bg-white rounded-[8px] w-full">
                                <TextArea maxLength={3000} rows={5} type="text" className="my-auto  form-control border text-[#B3B3B3]"
                                    placeholder="Add json message"
                                    value={inputMessage}
                                    onChange={(e) => { setInputMessage(e.target.value); }}
                                />
                                <div className="flex flex-row justify-between">
                                    <button className="text-white bg-black p-2 rounded border flex justify-center gap-[4px]"
                                        onClick={() => { addMessageToDb() }}
                                    >
                                        <span className='my-auto'>Insert Message</span>
                                    </button>
                                    <button className="text-white bg-black p-2 rounded border flex justify-center gap-[4px]"
                                        onClick={() => { sendWSRequest() }}
                                    >
                                        <span className='my-auto'>Send WS Request</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )}
        </>
    )
};

export default WSInputBox;