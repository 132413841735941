import React from 'react';

function Button({ text, onClick, className, secondary }) {
    return (
        <button
            className={`bg-[#18475A] text-white font-medium py-2 px-6 rounded-lg flex items-center ${secondary && 'bg-white !text-[#264653] border border-[#D1D1D1]'} ${className}`}
            onClick={onClick}
        >
            {text}
        </button>
    );
}

export default Button;
