import { Briefcase, CalendarBlank, Check, Clock, EnvelopeSimple } from '@phosphor-icons/react'
import React from 'react'
import Button from '../Button'

const steps = [
    {
        icon: <CalendarBlank className='text-[#18475A]' size={24} />,
        title: 'Scheduled chat with our legal support team',
        description: 'on 19th October, 2024 @ 9:00 AM'
    },
    {
        icon: <EnvelopeSimple className='text-[#18475A]' size={24} />,
        title: 'Lawyer Match Confirmation',
        description: <>You'll receive an email as soon as we match you with the <br />best lawyer for your needs.</>
    },
    {
        icon: <Briefcase className='text-[#18475A]' size={24} />,
        title: 'Lawyer assignment complete ',
        description: <>Once you accept the lawyer, we assign them to you. <br /> You can start requesting legal reviews, schedule time with them</>
    }
]

const NextSteps = () => {
    return (
        <div>
            <div className='px-10 py-10 bg-[#E4E4E44D]/30'>
                <p className='text-[#686868] text-base font-normal'>You’re all set! </p>
                <p className='text-[#18475A] text-2xl font-bold'>Here is what happens next</p>
            </div>
            <ul className='relative px-6 py-6 pr-40'>

                {/* Items */}
                {
                    steps.map(step => (
                        <li className='flex gap-3 mt-8'>
                            <div className='bg-white border-4 border-white w-fit h-fit z-[100] rounded-full'>
                                <div className='z-[100] bg-[#13866F0A]/10 flex-none flex justify-center items-center !w-12 !h-12 rounded-full'>
                                    {step.icon}
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <h6 className='text-[#353535] font-bold text-lg'>{step.title}</h6>
                                <p className='text-base mt-1 font-normal text-[#686868]'>{step.description}</p>
                            </div>
                        </li>
                    ))
                }




            </ul>
            <div className='flex justify-end px-6 pt-6 pb-6 mt-12 border-t'>
                <Button className={'py-2'} text={'Ok, got it!'} />
            </div>
        </div>
    )
}

export default NextSteps