import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { extractErrors } from "../helpers";
import axios from "axios";
// import { attemptAuthenticationRefresh } from "../UserAuthentication/AuthenticationSlice";

const BASE_ENDPOINT = process.env.REACT_APP_BACKEND_BASE_ENDPOINT;

export const createUserAPI = createAsyncThunk("onboarding/createUserAPI", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.post(`${BASE_ENDPOINT}/api/user/create`, {}, { headers: headers });
    return {
      data: data,
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});




export const getMessageType = createAsyncThunk("onboarding/getMessageType", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.post(
      `${BASE_ENDPOINT}/api/v0/message/get-type/${data.message}`,
      {},
      { headers: headers }
    );
    return {
      data: data,
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const getUserMetadata = createAsyncThunk("onboarding/getUserMetadata", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.get(
      `${BASE_ENDPOINT}/api/user/get-metadata`,
      { headers: headers }
    );
    return {
      data: data,
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});


export const getLawyerHub = createAsyncThunk("onboarding/getLawyerHub", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.get(
      `${BASE_ENDPOINT}/api/user/get-lawyer-hub-data`,
      { headers: headers }
    );
    return {
      data: data,
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});



const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: {
    status: "idle",
    error: "",
    createUserAPIStatus: "idle",
    userMetadata: {},
    userMetadataFetchStatus: "idle",
    userAlreadyExist: false,
    showUpgradePlanNudge: false,
  },
  reducers: {
    resetOnboardingStates: (state) => {
      state.status = "idle";
      state.error = "";
    },
    setShowUpgradePlanNudge: (state, action) => {
      state.showUpgradePlanNudge = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder

      // create user api : this creates new user on the backend
      .addCase(createUserAPI.pending, (state) => {
        state.createUserAPIStatus = "loading";
      })
      .addCase(createUserAPI.fulfilled, (state, action) => {
        state.createUserAPIStatus = "success";
      })
      .addCase(createUserAPI.rejected, (state, action) => {
        state.createUserAPIStatus = "failed";
        var response = action.payload?.error?.request?.response;
        if (response && response.indexOf("User already exists with email") > -1) {
          state.userAlreadyExist = true;
        }
      })
      // getUsermetadata api : this creates new user on the backend
      .addCase(getUserMetadata.pending, (state) => {
        state.userMetadataFetchStatus = "loading";
      })
      .addCase(getUserMetadata.fulfilled, (state, action) => {
        state.userMetadataFetchStatus = "success";
        state.userMetadata = action.payload.response;
        // state.userMetadata = {
        //   "subscription_type": "subscriber_business",
        //   "lawyer_status": "not_matched",
        //   "lawyer_info": {
        //     name: "Mr. Andrew Meyer",
        //     email: "andrew.meyer@inhouse.so",
        //     phone_number: "+91 7017991907",
        //     state_bar: "State of Ohio",
        //     specialties: "Government Contracts, Civil Law, Litigation, Commercial Law, Corporate Law",
        //     education: "L.L.B, Harvard Law School, US Post Graduate Diploma in Law, New York University",
        //     experience: "13+ years @BakerHostetler",
        //     hourly_rate: "75",
        //     calendly_link: "",
        //     pre_signed_image_url: "https://plus.unsplash.com/premium_photo-1678197937465-bdbc4ed95815?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cGVyc29ufGVufDB8fDB8fHww",
        //   },
        //   "show_new_lawyer_assigned_notification": false,
        //   "legal_review_credits_total": 0,
        //   "legal_review_credits_used": 0,
        //   "threads_total": 6,
        //   "threads_remaining": 3
        // }
      })
      .addCase(getUserMetadata.rejected, (state, action) => {
        state.userMetadataFetchStatus = "failed";
      })

      .addCase(getLawyerHub.pending, (state) => {
        state.lawyerHubFetchStatus = "loading";
      })
      .addCase(getLawyerHub.fulfilled, (state, action) => {
        state.lawyerHubData = action.payload.response;
        state.lawyerHubFetchStatus = "success";
      })
      .addCase(getLawyerHub.rejected, (state, action) => {
        state.lawyerHubFetchStatus = "failed";
      })

      .addCase(getMessageType.pending, (state) => {
        state.getMessageTypeStatus = "loading";
        state.getMessageTypeError = "";
      })
      .addCase(getMessageType.fulfilled, (state, action) => {
        state.getMessageTypeStatus = "success";
        state.getMessageTypeError = "";
        state.messageType = action.payload.response.message_type;
        localStorage.setItem("messageType", action.payload.response.message_type);
      })
      .addCase(getMessageType.rejected, (state, action) => {
        state.getMessageTypeStatus = "failed";
        state.getMessageTypeError = action.payload?.error;
        localStorage.setItem("messageType", "ask");
      });
  },
});

export default onboardingSlice.reducer;

export const { resetOnboardingStates, setShowUpgradePlanNudge } = onboardingSlice.actions;
