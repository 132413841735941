import React, { useEffect, useState } from "react";
import copy from "../../images/copy.svg";
import { ThumbsDown, ThumbsUp, X } from "@phosphor-icons/react";
import { useDispatch, useSelector } from "react-redux";
import ActionButton from "./ActionButton";
import {
  requestLegalReview, saveAsTemplate, acceptEditSuggestions,
  // updateSaveAsTemplateChats,
} from "../../pages/chatpage/chatSlice";
// import { legalReviewFeaturesNew } from "./ChatHeader";
import AskAvatar from "../../images/AskAvatar.svg";
import ReviewAvatar from "../../images/ReviewAvatar.svg";
import DraftAvatar from "../../images/DraftAvatar.svg";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { setRetainerModalOpen, setShowSubscriptionModal } from "../../pages/UserAuthentication/AuthenticationSlice";


const Chat = ({
  key,
  isAIReply,
  chatInfo,
  threadType,
  imageUrl,
  setLike,
  setDisLike,
  clickCopy,
  notificationMessage = null,
  isLastAiReply,
  isLastReply,
  viewerMode = "client",
  chatId,
  handleSend,
  selectedThread,
  legalReviewRequested,
  subscriptionType,
  userAction,
  setUserAction,
  size,
  isFirstAiMessage
  // showHyperLinkButton,
  // showTemplateButton,
  // showLegalReviewRequestButton
}) => {
  const currentDate = new Date(chatInfo?.created_at);
  const options = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZoneName: "short",
  };

  const [messageType, setMessageType] = useState("conversation");
  const [animate, setAnimate] = useState(false);
  const formattedDate = currentDate.toLocaleString("en-US", options);
  const lastMessageId = useSelector((state) => state.chat.lastMessageId);
  const getThreadStatus = useSelector((state) => state.chat.getThreadStatus);
  // const [retainermodalOpen, setRetainerModalOpen] = useState(false);
  const [confirmationTextModal, setConfirmationTextModal] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [confirmationTextSend, setConfirmationTextSend] = useState("");
  const [lawyer, setLawyer] = useState({});
  const [reviewRequest, setReviewRequest] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [hasAcceptEditSuggestions, setHasAcceptEditSuggestions] = useState(false);
  const [buttonsToShow, setButtonsToShow] = useState([]);
  const [showLegalReviewRequestButton, setShowLegalReviewRequestButton] = useState(true);
  const [showTemplateButton, setShowTemplateButton] = useState(true);
  const [showHyperLinkButton, setShowHyperLinkButton] = useState(true);

  const dispatch = useDispatch();

  const isDraft = window.location.pathname.includes("draft");

  const lawyerDetails = useSelector((state) => state.auth.lawyerInfo);
  const user = useSelector((state) => state.auth.user);
  const templateSaveStatus = useSelector((state) => state.chat.templateSaveStatus);
  const templateSaveError = useSelector((state) => state.chat.templateSaveError);

  const getRetainerStatus = useSelector((state) => state.chat.getRetainerStatus);
  const requestLegalReviewStatus = useSelector((state) => state.chat.requestLegalReviewStatus);
  const changesAccepted = useSelector((state) => state.chat.changesAccepted);
  const lastConversationalMessage = useSelector((state) => state.chat.lastConversationalMessage);
  const saveAsTemplateChats = useSelector((state) => state.chat.saveAsTemplateChats);
  const legalReviewRequestedChats = useSelector((state) => state.chat.legalReviewRequestedChats);
  const viewChatLinks = useSelector((state) => state.chat.viewChatLinks);


  useEffect(() => {
    setShowHyperLinkButton(viewChatLinks[viewChatLinks.length - 1] === chatInfo.id)
    updatebuttonsToShow();
  }, [viewChatLinks]);

  useEffect(() => {
    setShowTemplateButton(saveAsTemplateChats[saveAsTemplateChats.length - 1] === chatInfo.id)
    updatebuttonsToShow();
  }, [saveAsTemplateChats]);

  useEffect(() => {
    var show = legalReviewRequestedChats[legalReviewRequestedChats.length - 1] === chatInfo.id;
    setShowLegalReviewRequestButton(show)
    updatebuttonsToShow();
  }, [legalReviewRequestedChats]);


  useEffect(() => {
    const newAiMessage = localStorage.getItem("newAiMessage");
    if (newAiMessage && newAiMessage === chatInfo.id && isAIReply) {
      setAnimate(true);
      localStorage.removeItem("newAiMessage");
    }
  }, [getThreadStatus]);


  const legalReviewAction = () => {
    // if user is based in california, 
    // if subscribed, set retainer modal
    // else show subscription modal

    // if user is based oôutside california,
    // set retainer modal true
    if (user?.state === "California") {
      if (["free", null, "", "Free-Plan-USD-Monthly", "trial_expired", "subscription_expired", "trial"].indexOf(subscriptionType) !== -1) {
        setUserAction("legal-review-request");
        dispatch(setShowSubscriptionModal(true))
        window.analytics.track("subscription popup triggered", { "action": "legal review request", "chatId": chatId, "user_state": user?.state, user_email: user?.user_email });
      }
      else {
        dispatch(setRetainerModalOpen(true))
        window.analytics.track("retainer popup triggered", { "action": "legal review request", "chatId": chatId, "user_state": user?.state, user_email: user?.user_email });

      }
    }
    else {
      dispatch(setRetainerModalOpen(true))
      window.analytics.track("retainer popup triggered", { "action": "legal review request", "chatId": chatId, "user_state": user?.state, user_email: user?.user_email });
    }
  }

  const createLegalReviewRequest = () => {
    dispatch(requestLegalReview({ threadId: chatId, reviewRequest: reviewRequest, promoCode: promoCode }));
  };



  const formatText = (text) => {
    text = text.replace(/`/g, "&#96;");
    text = text.replace(/\\n/g, ' \n ');
    text = text.replace(/\n#/g, ' \n \n #');
    text = text.replace(/\n/g, ' \n\n ');
    text = text.replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
    text = text.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
    return text;
  }

  const hasAcceptEditSuggestionsButton = () => {
    if (chatInfo?.quick_action_buttons?.length > 0) {
      return chatInfo?.quick_action_buttons.some((btn) => btn.type === "accept_edit_suggestions");
    }
    return false;
  }
  const showQuickActionButton = (btn) => {
    if (btn.type === "accept_edit_suggestions" || btn.type === "quick_response") {
      if (isLastReply) {
        return true;
      }
      return false;
    }
    if (btn.type === "legal_review_request") {
      if (showLegalReviewRequestButton) {
        if (legalReviewRequested) {
          return false;
        }
        // if this is the last conversational message, then show the legal review button
        if (showLegalReviewRequestButton) {
          return true;
        }
        return false
      }
      else {
        return false;
      }
    }
    if (btn.type === "hyperlink" && showHyperLinkButton) {
      return true
    }
    if (btn.type === "save_as_template") {
      if (selectedThread?.template) {
        return false
      }
      if (showTemplateButton) {
        if (templateSaveStatus !== "success") {
          return true;
        }
      }
    }


    if (isLastReply || lastConversationalMessage === chatInfo.id) {
      if (changesAccepted) {
        return true;
      }
      if (hasAcceptEditSuggestionsButton()) {
        return false;
      }
      return true;
    }
  }

  const updatebuttonsToShow = () => {
    // debugger;
    if (chatInfo?.quick_action_buttons?.length > 0) {
      var buttons = [];
      chatInfo?.quick_action_buttons.map((btn) => {
        if (showQuickActionButton(btn)) {
          buttons.push(btn)
        }
      })
      setButtonsToShow(buttons)
      return [];
    }
  }

  useEffect(() => {
    updatebuttonsToShow()
  }, []);




  return (
    <div className="flex flex-col w-full px-0">
      <div className="flex flex-col w-[100%]">
        {chatInfo?.message_text &&
          <div className={`flex rounded-b-[5px] 
                   ${chatInfo?.source === "user" || chatInfo?.user ? "text-black font-[500] text-[24px] leading-[33.6px]" : "text-[16px] leading-[25.6px] text-[#333333]"}`
          }>
            <div className="chat">
              {isFirstAiMessage && threadType === "ask" && (<img src={AskAvatar} alt="AI" className="w-[32px] h-[32px] mb-[16px]" />)}
              {isFirstAiMessage && threadType === "draft" && (<img src={DraftAvatar} alt="AI" className="w-[32px] h-[32px] mb-[16px]" />)}
              {isFirstAiMessage && threadType === "review" && (<img src={ReviewAvatar} alt="AI" className="w-[32px] h-[32px] mb-[16px]" />)}
              {isFirstAiMessage && ["ask", "draft", "review"].indexOf(threadType) === -1 && (<img src={ReviewAvatar} alt="AI" className="w-[32px] h-[32px] mb-[16px]" />)}
              <Markdown remarkPlugins={[remarkGfm]}>{`${formatText(chatInfo?.message_text)}`}</Markdown>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default Chat;
