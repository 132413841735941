import { Lightbulb } from "@phosphor-icons/react";
import SavedDataPrompt from "../../components/Prompts/SavedDataPrompt";
import DocumentGeneratedPrompt from "../../components/Prompts/DocumentGeneratedPrompt";
import FollowupQuestionsPrompt from "../../components/Prompts/FollowupQuestionsPrompt";
import Chat from "../../components/chat/Chat";
import ChatMessage from "../chat/ChatMessage";


export const getCalculatedIndices = (placeholders) => {
    var calculatedIndices = {}
    placeholders.forEach(placeholder => {
        placeholder["indices"].forEach(index => {
            calculatedIndices[index.start_index] = {
                "startIndex": index.start_index,
                "endIndex": index.end_index,
                "type": "placeholder",
                "placeholder": placeholder
            }
        });
    });
    return calculatedIndices
}


export const setBackgroundColors = (quillRef, placeholders) => {
    for (var i = 0; i < placeholders.length; i++) {
        placeholders[i]["indices"].forEach(index => {
            quillRef?.current?.editor.formatText(index.start_index, index.end_index - index.start_index, {                   // unbolds 'hello' and set its color to blue
                'background-color': 'rgba(198, 29, 202, 0.2)'
            });
        });
    }
}

export const removeBackgroundColors = (quillRef, placeholders) => {
    for (var i = 0; i < placeholders.length; i++) {
        placeholders[i]["indices"].forEach(index => {
            quillRef?.current?.editor.formatText(index.start_index, index.end_index - index.start_index, {                   // unbolds 'hello' and set its color to blue
                'background-color': 'transparent'
            });
        });
    }
}

export const setUpDocument = (data, Delta) => {
    var defaultData = new Delta();
    // insert text and attributes
    for (var i = 0; i < data.ops.length; i++) {
        if (data.ops[i].insert) {
            defaultData.insert(data.ops[i].insert, data.ops[i].attributes);
        }
    }
    return defaultData
}

export const updatePlaceholderValue = (quillRef, placeholders, placeholderIndex, updatedText, setPlaceholders) => {
    var initialValue = placeholders[placeholderIndex]["current_value"];
    placeholders[placeholderIndex]["current_value"] = updatedText;
    var indices = placeholders[placeholderIndex]["indices"];
    var stringLength = updatedText.length - initialValue.length;
    if (stringLength < 0) {
        stringLength = 0;
    }
    var forward = 0;
    var newIndices = []
    indices.forEach(index => {
        quillRef?.current?.editor.formatText(index.start_index + forward, forward, {                   // unbolds 'hello' and set its color to blue
            'background-color': 'transparent'
        });

        quillRef?.current?.editor.deleteText(index.start_index + forward, index.end_index - index.start_index);
        quillRef?.current?.editor.insertText(index.start_index + forward, updatedText);
        quillRef?.current?.editor.formatText(index.start_index + forward, index.end_index - index.start_index, {                   // unbolds 'hello' and set its color to blue
            'background-color': 'transparent'
        });
        // quillRef?.current?.editor.formatText(index.start_index + forward, updatedText.length, {                   // unbolds 'hello' and set its color to blue
        //     'background-color': 'rgba(148, 29, 202, 0.2)'
        // });

        // I think the following might not be required if we are going to get the updated indices from the backend
        var new_start_index = index.start_index + forward;
        var new_end_index = index.start_index + updatedText.length + forward;
        newIndices.push({ start_index: new_start_index, end_index: new_end_index });
        forward += stringLength;

    });
    // I think the following might not be required if we are going to get the updated indices from the backend
    // we might have to update the placeholders data with the updated text though 
    placeholders[placeholderIndex]["indices"] = newIndices
    setPlaceholders([...placeholders]);

}


export const renderMessage = (message, index, threadData, size = "full", email, threadType) => {
    var isFirstAiMessage = false;
    if (index > 0) {

        if (threadType === "ask" || threadType === "draft") {
            if (!message.is_user_message) {
                var previousMessage = threadData.messages[index - 1];
                if (previousMessage.user || previousMessage.is_user_message) {
                    isFirstAiMessage = true;
                }
            }
        }
    }
    var message_type = message.message_type;
    if (!message_type) {
        if (message.type) {
            message_type = message.type;
        }
        else {
            message_type = "conversation"
        }
    }
    switch (message_type) {
        case "questions":
            var totalMessageCount = threadData.messages.length;

            return <>
                <FollowupQuestionsPrompt
                    message={message}
                    messageIndex={index}
                    totalMessageCount={totalMessageCount}
                    activeQuestionIndex={threadData.questions?.active_index}
                    answers={threadData.questions?.answers}
                    skipped={threadData.questions?.skipped}
                    size={size}
                    threadType={threadType}
                    isFirstAiMessage={isFirstAiMessage}
                    email={email}
                ></FollowupQuestionsPrompt>
            </>
        // break;
        case "draft_message":
        case "ask_message":
            return (<><ChatMessage
                key={index}
                chatInfo={message}
                viewChatLinks={[]}
                message_type={message_type}
                size={size}
                email={email}
                isFirstAiMessage={isFirstAiMessage}
            />
                {/* <p>{JSON.stringify(message)}</p> */}
            </>);
        case "conversation":
            return (<><Chat
                key={index}
                chatInfo={message}
                viewChatLinks={[]}
                threadType={threadData.thread_type}
                size={size}
                email={email}
                isFirstAiMessage={isFirstAiMessage}
            />
                {/* <p>{JSON.stringify(message)}</p> */}
            </>);
        case "information_gathered_prompt":
            return (<>
                <SavedDataPrompt
                    threadType={threadData?.thread_type}
                    chatInfo={message}
                    threadId={threadData?.id}
                    size={size}
                    email={email}
                ></SavedDataPrompt >
            </>);
        case "information":
            return <>
                <div className="flex flex-row bg-[#faf6e8] gap-[12px] rounded-[8px] px-[10px] py-[8px]">
                    <Lightbulb size={10} className="text-[#DCA218] my-auto" />
                    <p className="text-[12px] leading-[25.6px] text-[#686868]">{message?.text}</p>
                </div>
            </>;
        case "document_generated":
            return <><DocumentGeneratedPrompt
                email={email}
            /></>
        default:
            <><p>Message type not found. Here is the message: {JSON.stringify(message)}</p></>
        // return <><Chat
        //     key={index}
        //     chatInfo={message}
        //     viewChatLinks={[]}
        //     threadType={threadData.thread_type}
        //     size={size}
        //     email={email}
        // /></>
    }
}