import React, { useEffect, useRef, useState } from 'react';
import { Modal } from "antd";
import {
    X,
} from "@phosphor-icons/react";
import { useDispatch, useSelector } from 'react-redux';
import { closePaymentModal, setCheckoutUrl } from '../../chatSlice';

const PaymentModal = (props) => {
    const dispatch = useDispatch();
    const BASE_URL = process.env.REACT_APP_URL;
    const openPaymentModal = useSelector((state) => state.chat.openPaymentModal);
    const iframeUrl = useSelector((state) => state.chat.checkoutUrl);

    const closeModal = () => {
        dispatch(closePaymentModal());
    }
    const setIframeUrl = (url) => {
        dispatch(setCheckoutUrl(url));
    }
    // const [chec, setIframeUrl] = useState("https://inhouse-test.chargebee.com/pages/v4/6DuJu5dmC6cdMaoVq3emAdiczzTzN88Ql/?signature=oFLfdhIcCjF06VK9jyo4KapzPi8yXTTo")
    const iframeRef = useRef(null);
    // alert(openPaymentModal)

    useEffect(() => {

        const checkIframeUrl = () => {
            try {
                const currentUrl = iframeRef?.current?.contentWindow?.location?.href;
                if (currentUrl !== iframeUrl) {
                    setIframeUrl(currentUrl);
                }
            } catch (e) { }
        };

        const interval = setInterval(checkIframeUrl, 3000);
        return () => clearInterval(interval);
    }, [iframeUrl]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <Modal
            centered
            open={openPaymentModal}
            // open={true}
            className="review-pop-up h-full bg-[#fff] overflow-hidden "
            footer={null}
        >
            <div className="w-screen h-screen flex flex-col ">
                <div className="flex w-full flex-col border-b gap-4 py-[20px] px-[41px]">
                    <div className='flex gap-[8px] w-full justify-between'>
                        <h1 className="text-[20px] font-[600] leading-[28px] font-[Lora] italic">
                            Complete Payment
                        </h1>
                        <X
                            size={30}
                            className="hover:cursor-pointer"
                            color="black"
                            onClick={() => { closeModal() }}
                            id="cancel-draft-document-button"
                        />
                    </div>
                </div>
                <div className="w-full">
                    {iframeUrl && (
                        <iframe src={iframeUrl} width="100%" height={window.innerHeight - 100} ref={iframeRef} title="Complete Payment" />
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default PaymentModal