import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "@clerk/clerk-react"
import BaseLayout from '../layouts/BaseLayout';
import useValidatePageAccess from "../../hooks/useValidatePageAccess";
import TopNav from "./Navs/TopNav";
import { LightbulbFilament } from "@phosphor-icons/react";
import ChatBoxComponent from "./components/ChatBoxComponent";
import { useDispatch, useSelector } from "react-redux";
import { resetRedirectTo, setNewThreadMessage } from "./messageSlice";
import { setEmail } from "../Homepage/HomeSlice";
import { useWebSocketContext } from "../../WebsocketProvider";
import { updateMessagingDisabled } from "./chatSlice";
import { setShowExploreMoreModal } from "../../modalSlice";
import ExploreMoreModalComponent from "../../components/chat/Modals/ExploreMoreModal";
import DraftDocumentQuestionaireModal from "./Modals/DraftDocumentQuestionaireModal";

const AskPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    var { message } = location.state || {};
    const draftDocumentModalRef = useRef(null);

    const { isLoaded, isSignedIn, user } = useUser();
    const [inputMessage, setInputMessage] = useState(message || "");
    const [referenceDocId, setReferenceDocId] = useState(null);
    const { sendMessage, createMessage } = useWebSocketContext();

    const askSuggestions = useSelector(state => state.homeslice.askhomeSuggestions);
    const redirectTo = useSelector(state => state.messageSlice.redirectTo);
    useEffect(() => {
        if (user && isLoaded && isSignedIn) {
            if (!user?.firstName || !user?.unsafeMetadata?.organization_name) {
                navigate('/onboarding')
            }
            dispatch(setEmail(user.primaryEmailAddress.emailAddress));
        }
    }, [user, isLoaded, isSignedIn]); // eslint-disable-line

    useEffect(() => {
        setInputMessage(message);
    }, [message]); // eslint-disable-line

    const email = useSelector((state) => state.homeslice.email);

    useEffect(() => {
        if (redirectTo) {
            var redirectUrl = redirectTo;
            dispatch(resetRedirectTo(null));
            navigate(redirectUrl);
        }
    }, [redirectTo]); // eslint-disable-line

    useValidatePageAccess();

    const onsubmit = () => {
        window.analytics.track("new ask thread. sending first message", { "user_email": email, "message": inputMessage })
        dispatch(setNewThreadMessage(inputMessage))
        var message = createMessage("ask", "create-thread", { "message": inputMessage, "reference_doc_storage_id": referenceDocId });
        var response = sendMessage(message)
        if (!response) {
            dispatch(updateMessagingDisabled(true));
        }
    }

    const exploreAllClicked = () => {
        window.analytics.track("Explore all clicked (homepage)", { "user_email": email });
        dispatch(setShowExploreMoreModal(true));
    }

    return (
        <>
            {isLoaded ? (
                <BaseLayout>
                    <div className="flex flex-col w-screen">
                        <div className="flex flex-col justify-between w-full h-[80%] pb-[56px]">
                            <TopNav page="ask_home"></TopNav>
                            <div className="ask_page_central_content flex flex-col my-auto gap-y-[32px]">
                                <h3 className="justify-center flex flex-row gap-[10px]">
                                    <LightbulbFilament size={24} />
                                    <span>Quick suggestions to get started</span>
                                </h3>
                                <div className="flex flex-row w-full h-[176px]">
                                    <div className="mx-auto flex flex-row justify-between gap-[16px]">
                                        {askSuggestions.map((suggestion, index) => (
                                            <div className="cursor-pointer flex flex-col w-[261px] h-[176px] bg-white rounded-[8px] border gap-[10px] p-[26px]" onClick={() => { setInputMessage(suggestion.paragraph) }}>
                                                <h4 className="text-[12px] leading-[28px] text-[#686868] uppercase font-[500] tracking-[2%]">{suggestion.title}</h4>
                                                <p className="font-[500] text-[16px] leading-[28px]">
                                                    {suggestion.paragraph}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <p className="text-center text-[#C61DCA] text-[12px] font-[600] uppercase" onClick={exploreAllClicked}>Explore More</p>
                            </div>
                        </div>
                        <div className="flex flex-col w-full pb-[56px]">
                            <ChatBoxComponent
                                size="full"
                                allowAttachReference={true}
                                inputMessage={inputMessage}
                                setInputMessage={setInputMessage}
                                onsubmit={onsubmit} />
                        </div>
                    </div>
                    <DraftDocumentQuestionaireModal
                        email={email}
                        ref={draftDocumentModalRef} />
                    <ExploreMoreModalComponent />


                </BaseLayout>
            )
                : <>
                    {
                        (isLoaded) ?
                            <div className="w-full h-full flex justify-center items-center">
                                <p className="text-red-500">Failed to load user details. Please refresh the page.</p>
                            </div> :
                            <div className="w-full h-full flex justify-center items-center mx-auto mt-[200px]">
                                {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
                                {/* <div class="rotating-cursor !h-[50px]"></div> */}
                                <p className="text-black text-[40px]">{isLoaded}</p>
                            </div>
                    }
                </>}
        </>
    );
};

export default AskPage;