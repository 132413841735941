import { X } from '@phosphor-icons/react';
import React from 'react';

const DraftDocumentModalHeader = (props) => {
    const { closeModal, isDocumentDetailsScreen, concernedPartiesScreen, finishingTouchesScreen } = props;
    const getTitle = () => {
        if (isDocumentDetailsScreen) {
            return "Draft a new legal document";
        }
        if (concernedPartiesScreen) {
            return "Enter Party Details";
        }
        if (finishingTouchesScreen) {
            return "Finishing Touches";
        }
    }
    return (
        <div className="flex w-[860px] flex-col border-b gap-4 py-[20px] px-[41px]">
            <div className='flex gap-[8px] w-full justify-between'>
                <h1 className="text-[20px] font-[600] leading-[28px] font-[Lora] italic">
                    {getTitle()}
                </h1>
                {finishingTouchesScreen ? <span></span> :
                    <X
                        size={30}
                        className="hover:cursor-pointer"
                        color="black"
                        onClick={() => closeModal()}
                        id="cancel-draft-document-button"
                    />}
            </div>
            <div className="flex mx-auto gap-[4px] w-full">
                <div className={`flex flex-row gap-[12px] rounded-[90px] ${isDocumentDetailsScreen ? "activated-tab" : "non-activated-tab"}`} id="document-details-tab">
                    <div className="circle rounded-[50px] w-[24px] h-[24px] px-auto py-auto text-center">
                        1
                    </div>
                    <p className={`my-auto text-[14px] ${isDocumentDetailsScreen ? 'text-[#302F2F] font-[600]' : "text-[#838282]"}`}>
                        Document details
                    </p>
                </div>
                <div className="border-[#B6B6B6] border-dashed border-[1px] w-[40px] align-items-center justify-items-center h-[1px] my-auto"></div>
                <div className={`flex flex-row gap-[12px] rounded-[90px] ${concernedPartiesScreen ? "activated-tab" : "non-activated-tab"}`} id="concerned-parties-tab">
                    {/* <div className="flex flex-row gap-[12px] w-[173px] h-[40px] px-[0px] py-[8px] rounded"> */}
                    <div className="circle rounded-[50px] w-[24px] h-[24px] px-auto py-auto text-center">
                        2
                    </div>
                    <p className={`my-auto text-[14px] ${concernedPartiesScreen ? 'text-[#302F2F] font-[600]' : "text-[#838282]"}`}>
                        Party Selection
                    </p>
                </div>
                <div className="border-[#B6B6B6] border-dashed border-[1px] w-[40px] align-items-center justify-items-center h-[1px] my-auto"></div>
                <div className={`flex flex-row gap-[12px] rounded-[90px] ${finishingTouchesScreen ? "activated-tab" : "non-activated-tab"}`} id="concerned-parties-tab">
                    {/* <div className="flex flex-row gap-[12px] w-[173px] h-[40px] px-[0px] py-[8px] rounded"> */}
                    <div className="circle rounded-[50px] w-[24px] h-[24px] px-auto py-auto text-center">
                        3
                    </div>
                    <p className={`my-auto text-[14px] ${finishingTouchesScreen ? 'text-[#302F2F] font-[600]' : "text-[#838282]"}`}>
                        Finishing Touches
                    </p>
                </div>

            </div>
        </div>
    );
};

export default DraftDocumentModalHeader;