import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { Check, Cross, Spinner, X } from "@phosphor-icons/react";
import { useDispatch, useSelector } from 'react-redux';
import { updateTitle } from '../../../../pages/chatpage/threadsSlice';
import { setViewChatHistory } from '../../../../pages/Homepage/HomeSlice';
import TextArea from 'antd/es/input/TextArea';

const UpdateThreadTitleModal = (props) => {
    const {
        open,
        setActionOnThread,
        loading,
        setLoading,
        threadInfo,
        setRenameActiveId
    } = props;

    const dispatch = useDispatch();

    const [newTitle, setNewTitle] = useState("");
    const thread = threadInfo.thread;
    const duration = threadInfo.duration;

    const updatedTitle = useSelector((state) => state.threads.updatedTitle);

    useEffect(() => {
        if (updatedTitle?.status === "success") {
            dispatch(setViewChatHistory(true));
            setActionOnThread("");
            setLoading(false);
            setRenameActiveId("");
        }
    }, [updatedTitle]); // eslint-disable-line

    const updateThreadTitle = (newTitle) => {
        dispatch(updateTitle({ id: thread?.id, title: newTitle, duration: duration }));
    };


    useEffect(() => {
        setNewTitle(thread?.title);
    }, [thread?.title, open])


    return (
        // <Modal
        //     centered
        //     open={open}
        //     onOk={() => setActionOnThread("")}
        //     onCancel={() => setActionOnThread("")}
        //     className="review-pop-up h-full rounded-[30px] bg-[#fff] overflow-hidden "
        //     footer={null}
        // >
        <div className="w-full h-[32px] flex flex-col">
            <div className="flex flex-row justify-between w-full">
                <TextArea
                    id="update-title"
                    name="updateTitle"
                    value={newTitle}
                    onChange={(e) => {
                        setNewTitle(e.target.value);
                    }}
                    rows={1}
                    style={{ resize: "none" }}
                    className="px-[8px] !w-[300px] rounded-[5px] text-[12px] font-[500] text-black !h-[32px]  placeholder:text-black overflow-auto scrollbar-thin scrollbar-thumb-gray-500"
                    placeholder="Add New Chat Title"
                />
                <button
                    disabled={loading || !newTitle}
                    className="text-[16px] font-[700] w-[30px] bg-[#13866F] text-white rounded-[5px] border border-[#13866F]"
                    onClick={() => {
                        setLoading(true);
                        updateThreadTitle(newTitle);
                    }}
                    id="update-title-button"
                >
                    {loading ? <Spinner size={16} className="animate-spin mx-auto my-auto" color="black" /> :
                        <Check size={15} weight="bold" className="mx-auto my-auto mr-2 text-white" />}
                </button>
                <button
                    className="text-[16px] font-[700]  bg-white  leading-[1] rounded-[5px] w-[30px] border border-black"
                    onClick={() => { setActionOnThread(""); setRenameActiveId("") }}
                    disabled={loading}
                    id="cancel-update-title-button"
                >
                    <X size={15} className="mr-2 text-black mx-auto my-auto" weight='bold' />
                </button>
            </div>
        </div >
        // </Modal>
    )
}

export default UpdateThreadTitleModal