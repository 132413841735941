import { Check, SkipForward, Sparkle } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import ForwardIcon from "../../images/forward.svg";
import FollowupInputPrompt from './FollowupInputPrompt';
import { useDispatch, useSelector } from 'react-redux';
import { setUpdatingQuestion } from '../../pages/chatpage/messageSlice';
import AskAvatar from "../../images/AskAvatar.svg";
import ReviewAvatar from "../../images/ReviewAvatar.svg";
import DraftAvatar from "../../images/DraftAvatar.svg";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { useWebSocketContext } from '../../WebsocketProvider';

const FollowupQuestionsPrompt = ({ message, skipped, isFirstAiMessage, threadType }) => {

    const dispatch = useDispatch();

    const [answers, setAnswers] = useState({})
    const [activeIndex, setActiveIndex] = useState(0);
    const [questionsAnswered, setQuestionsAnswered] = useState([]);
    const [questionsSkipped, setQuestionsSkipped] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [viewAll, setViewAll] = useState(false);
    const [classes, setClasses] = useState("")

    const { sendMessage, createMessage } = useWebSocketContext();
    const threadsData = useSelector(state => state.messageSlice.threadData);

    useEffect(() => {
        if (threadsData.questions) {
            setActiveIndex(threadsData.questions.active_index);
            setQuestionsAnswered(threadsData.questions.answers);
            setQuestions(threadsData.questions.questions);
            setQuestionsSkipped(threadsData.questions.skipped);
        }
    }, [threadsData]);


    // useEffect(() => {
    //     // the questions can come from message, or it can come up from the threadData.questions.questions
    //     setQuestions(message.questions);
    // }, [message]);

    const viewAllQuestions = () => {
        setViewAll(true);
        setClasses("")
        var myElement = document.getElementById('follow-up');
        var topPos = myElement.offsetTop;
        document.getElementById('scrolling_div').scrollTop = topPos;
    }

    useEffect(() => {
        var answeredIndices = Object.keys(answers).map((key) => parseInt(key));
        setQuestionsAnswered(answeredIndices);
    }, [answers]);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         var followupElement = document.querySelector("#follow-up")
    //         var isvisibleFull = isInViewport(followupElement);
    //         debugger;
    //         var isvisibleSmallView;
    //         try {
    //             var smallView = document.querySelector("#small-view")
    //             isvisibleSmallView = isInViewport(smallView);
    //         }
    //         catch {
    //             isvisibleSmallView = false
    //         }

    //         if (!isvisibleFull) {
    //             setClasses("fixed z-50 top-[65px] w-[736px]")
    //             setViewAll(false)
    //         }
    //         else if (isvisibleFull && followupElement.scrollHeight > 200) {
    //             setViewAll(true)
    //             setClasses("")
    //             var myElement = document.getElementById('follow-up');
    //             var topPos = myElement.offsetTop + 100;
    //             document.getElementById('scrolling_div').scrollTop = topPos;
    //         }
    //     }, 1000);

    //     return () => clearInterval(interval);
    // }, []);

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    const skipQuestion = (question, index) => {
        var data = {
            "id": question.id,
            "activeIndex": index,
            "action": "skip"
        }
        dispatch(setUpdatingQuestion(data))
        data = {
            question_id: question.id,
            action: "skipped",
            text: null
        }
        var message = createMessage("draft", "update-question", data)
        sendMessage(message)
        // dispatch(skipFollowupQuestions(index))
    }

    const submitAnswer = (question, index, response) => {
        var data = {
            "id": question.id,
            "activeIndex": index,
            "action": "answer",
            "response": response
        }
        dispatch(setUpdatingQuestion(data))
        data = {
            question_id: question.id,
            action: "submitted",
            text: response,
        }
        var message = createMessage("draft", "update-question", data)
        sendMessage(message)
    }



    const formatText = (text) => {
        text = text.replace(/`/g, "&#96;");
        text = text.replace(/\\n/g, ' \n ');
        text = text.replace(/\n#/g, ' \n \n #');
        text = text.replace(/\n/g, ' \n\n ');
        text = text.replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
        text = text.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
        return text;
    }


    return (
        <div className={`${classes}`} id="follow-up">
            {/* {(questionsSkipped.length + questionsAnswered.length === questions.length ) && ( */}
            {(true) && (
                <>
                    {/* messageIndex = index of this particular message in the chat 
                totalMessageCount = total number of messages in the chat. 

                This is required to check when the follow up questions should be fully visible, or when it should be collapsed
            */}

                    <div className="flex flex-col w-[100%] mt-[20px]">
                        {message?.message_text &&
                            <div className={`flex rounded-b-[5px] 
                                   ${message?.is_user_message === true ? "text-black font-[500] text-[24px] leading-[33.6px]" : "text-[16px] leading-[25.6px] text-[#333333]"}`
                            }>
                                <div className="chat">
                                    {isFirstAiMessage && threadType === "draft" && (<img src={DraftAvatar} alt="AI" className="w-[32px] h-[32px] mb-[16px]" />)}
                                    <Markdown remarkPlugins={[remarkGfm]}>{`${formatText(message?.message_text)}`}</Markdown>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='flex flex-col rounded-[12px]'>
                        {questions.map((question, index) => (
                            <div key={index} className={`flex border w-full bg-white
                                                 ${index === 0 ? "rounded-t-[12px]" : ""} 
                                                 ${index === message.questions.length - 1 ? "rounded-b-[12px]" : ""}
                                                 ${activeIndex === index ? "!border-l-[#13866F] !border-l-4" : "border-collapse "}`}>
                                <div className={`flex flex-col p-[16px] w-full gap-[20px]`}>
                                    <div className="flex justify-between">
                                        <p className="flex my-auto text-[14px] leading-[19.6px] font-[500] text-[#333333] gap-[8px]" >
                                            {/* {question.text} */}
                                            <span className='mx-auto my-auto  flex items-center justify-center bg-[#F2F2F2] w-[24px] h-[24px] rounded-[31.5px] border text-[#7F8C8D]'>
                                                {Object.keys(questionsAnswered).indexOf(index) === -1 ? <>{index + 1} </> : <Check size={14} weight="bold" className="text-[#137513]" />}
                                            </span>
                                            <span className='mx-auto my-auto text-[16px] font-[500]'>{question?.text || question?.question}</span>
                                        </p>
                                        {index > activeIndex && (
                                            <p className="my-auto w-[50px]">
                                                <svg
                                                    className={`w-6 h-8 transition-transform duration-300 transform ${index === activeIndex ? "rotate-180" : ""
                                                        }`}
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                // onClick={() => { if (activeIndex !== index) { setActiveIndex(index) } else { setActiveIndex(null) } }}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M19 9l-7 7-7-7"
                                                    />
                                                </svg>
                                            </p>
                                        )}
                                    </div>
                                    {(activeIndex >= index || Object.keys(questionsAnswered).indexOf(index) !== -1) && (
                                        <div className="flex flex-col gap-[20px]">
                                            {/* answers need to be taken care of. We will have to pass on something different here as input */}
                                            <FollowupInputPrompt index={index} answers={answers}
                                                questionsAnswered={questionsAnswered}
                                                activeIndex={activeIndex}
                                                setAnswers={setAnswers} />
                                            {activeIndex === index && (
                                                <div className='flex justify-between gap-[8px] w-full'>
                                                    <p className='flex flex-row text-[#13866F] gap-[8px]'>
                                                        {/* <Sparkle size={14.5} className='my-auto' weight='bold' />
                                                        <span className='text-[14px] my-auto leading-[22.5px] font-[600]'>Explain this</span> */}
                                                    </p>
                                                    <div className='flex flex-row gap-[8px]'>
                                                        <button className='border-0 text-[#001613] text-[14px] leading-[22.4px] font-[600] rounded-[8px] p-[8px] cursor-pointer' onClick={() => skipQuestion(question, index)}>Skip</button>
                                                        <button className='bg-[#13866F] text-[#fff] rounded-[4px] px-[16px] h-[30px] text-[14px] cursor-pointer leading-[22.4px] my-auto align-items-center'
                                                            // disabled={}
                                                            onClick={() => submitAnswer(question, index, answers[index])}
                                                        >Next</button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {skipped.indexOf(index) !== -1 && (
                                        <div className="flex flex-col gap-[8px] bg-[#FAFAFA] p-[8px] ml-[30px] rounded-[8px]">
                                            <p className='flex flex-row text-[#13866F] gap-[8px]'>
                                                <Sparkle size={14.5} className='my-auto text-[#686868]' weight='bold' />
                                                <span className='text-[14px] my-auto leading-[22.5px] font-[600] text-[#686868]'>AI will pick the best option based on your needs</span>
                                            </p>
                                        </div>
                                    )}

                                </div>
                            </div>
                        ))}
                    </div >
                    {(questionsAnswered.length > 0) && (
                        <div className="w-full flex justify-end mt-[12px]">
                            <button className='rounded-[8px] bg-[#264653] text-white text-[14px] leading-[19.6px] p-[12px]'>Submit & Generate Draft</button>
                        </div>
                    )}
                    {(questionsAnswered.length === 0) && (
                        <div className="w-full flex justify-end">
                            <button className='rounded-[8px]  text-[#264653] text-[14px] leading-[19.6px] p-[12px] flex gap-[4px]'>
                                <span>Skip all questions</span>
                                <img src={ForwardIcon} className='my-auto w-[19.5px]' alt="forward-icon" />
                            </button>
                        </div>
                    )}
                </>
            )}
            {/* this is used to show only the active message when the chat is being scrolled.
            
            This will render the message when the message is being scrolled and the follow up questions are not visible.
            If the user is scrolling and the follow up questions are not visible, then only the active question will be shown.
            */}
            {/* {(messageIndex !== (totalMessageCount - 1) && !viewAll) && ( */}
            {false && (
                <div className='flex flex-col rounded-[12px] bg-red-500' id="small-view">
                    <div className={`flex border w-full bg-white
                                                 rounded-t-[12px] border-yellow-500`}>
                        <div className={`flex flex-col p-[16px] w-full gap-[20px]`}>
                            <div className="flex justify-between">
                                <p className="flex my-auto text-[14px] leading-[19.6px] font-[500] text-[#333333] gap-[8px]" >
                                    {/* {question.text} */}
                                    <span className='mx-auto my-auto  flex items-center justify-center bg-[#F2F2F2] w-[24px] h-[24px] rounded-[31.5px] border text-[#7F8C8D]'>
                                        <>{activeIndex + 1} </>
                                    </span>
                                    <span className='mx-auto my-auto text-[16px] font-[500]'>{message.questions[activeIndex]?.text}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex border border-collapse justify-between rounded-b-[12px] py-[10px] px-[24px] bg-[#FCFCFA]">
                        <div className="w-[209px] flex gap-[10px]">
                            <div className='w-[100px] flex my-auto'>
                                <div className={`w-${Math.ceil(parseInt((activeIndex) * 100 / message.questions.length) / 10) * 10} h-[3px] bg-red-500`}></div>
                                <div className={`w-${Math.ceil(parseInt(100 - (activeIndex) * 100 / message.questions.length) / 10) * 10} bg-[#E4E4E4] h-[3px]`}></div>
                            </div>
                            <div className="text-[#686868] text-[16px]">{Math.ceil(parseInt((activeIndex) * 100 / message.questions.length) / 10) * 10}% done</div>
                        </div>
                        <div className="flex text-[#407D8B] font-[700] text-[16px] leading-[25.6px] cursor-pointer">
                            <p onClick={() => { viewAllQuestions() }}>VIEW ALL ({message.questions.length})</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FollowupQuestionsPrompt;