import React from 'react';
import { Briefcase, FileText, User, X } from '@phosphor-icons/react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setShowSavedDataModal } from '../../pages/chatpage/messageSlice';

const SavedDataModalComponent = ({ chatInfo }) => {
    const dispatch = useDispatch();
    const draftType = "Non Disclosure Agreement"; // chatInfo.inferredMetadata.document_type;
    const description = "This is a non-disclosure agreement between two parties"; // chatInfo.inferredMetadata.description;
    const fileSelected = {
        name: "File Name.pdf",
        size: "100kb",
        link: ""
    } // chatInfo.inferredMetadata.attached_document;
    const parties = useSelector((state) => state.homeslice.savedPartiesForDraft);// chatInfo.inferredMetadata.parties_involved;
    const showSavedDataModal = useSelector((state) => state.messageSlice.showSavedDataModal);
    const email = useSelector((state) => state.homeslice.email);

    const closeModal = () => {
        window.analytics.track("Drafting - Data Captured Modal Closed", {
            "user_email": email
        })
        dispatch(setShowSavedDataModal(false));
    }

    return (
        <Modal
            className="data-captured-modal rounded-[8px] border border-b-0 overflow-hidden w-[816px] bg-white pb-0"
            open={showSavedDataModal}
            footer={null}
        >
            <div className="flex flex-col gap-[12px]">
                <div className="flex flex-row justify-between pl-[30px] pr-[20px] py-[20px] border rounded-t-[8px]">
                    <p className='font-[Lora] italic font-[600] text-[20px] leading-[28px] my-auto'>
                        Data Captured for Drafting
                    </p>
                    <X size={13.5} className="text-[#343330] my-auto cursor-pointer" onClick={closeModal} />
                </div>
                <div className='flex flex-col gap-[48px] pl-[60px] pt-[20px] h-[70vh] overflow-y-scroll pb-[50px]'>
                    <div className="flex flex-col gap-[24px]">
                        <div className="flex flex-row gap-[10px]">
                            <div className='w-[24px] h-[24px] rounded-[100px] bg-[#F2F2F2] flex'>
                                <p className="text-[#001613] text-[14px] font-[500] mx-auto my-auto">1</p>
                            </div>
                            <p className="font-[600] text-[18px] leading-[25.2px] text-[#000000]">Document Details</p>
                        </div>
                        <div className="flex flex-col gap-[10px] w-[656px]">
                            <p className="font-[600] text-[16px] leading-[20.83px] text-[#000000]">Document Type</p>
                            <p className="font-[400] p-[16px] text-[14px] leading-[18.23px] border-[#E4E4E4] border rounded-[8px]">{draftType}</p>
                        </div>

                    </div>
                    <div className="flex flex-col gap-[24px]">
                        <div className="flex flex-row gap-[10px]">
                            <div className='w-[24px] h-[24px] rounded-[100px] bg-[#F2F2F2] flex'>
                                <p className="text-[#001613] text-[14px] font-[500] mx-auto my-auto">2</p>
                            </div>
                            <p className="font-[600] text-[18px] leading-[25.2px] text-[#000000]">Party Details</p>
                        </div>
                        {parties.map((party, index) => (
                            <div className={`flex flex-col gap-[16px] w-[656px] border p-[18px] rounded-[8px] cursor-pointer`}
                            >
                                <div className='flex justify-between w-[624px] mx-auto'>
                                    <div className='flex flex-col gap-[8px]'>
                                        <p className='text-[16px] font-[600] text-[#141414]'>{party.name}</p>
                                    </div>
                                    <div className={`flex flex-row gap-[8px] h-[24px] p-[4px] bg-[${party.type === "company" ? "#DCA218" : "#13866F14"}]`}
                                    >
                                        {party.type === "company" &&
                                            <Briefcase size={16} className="text-[#DCA218] my-auto" />
                                        }
                                        {party.type === "individual" &&
                                            <User size={16} className="text-[#DCA218] my-auto" />
                                        }
                                        <p className='text-[12px] font-[500] text-[#686868] my-auto capitalize'>{party.type === "individual" ? "Individual" : party.incorporation_type}</p>
                                    </div>
                                </div>
                                <div className="border-t h-[1px] w-[624px]"></div>
                                <div className="">
                                    <table className="gap-y-[8px] flex flex-col">
                                        <tbody className='flex flex-col gap-y-[8px]'>
                                            <tr className="gap-[12px]">
                                                <td className='text-[12px] text-[#000] font-[600] w-[92px]'>State</td>
                                                <td className="text-[#77787B]">{party.state}</td>
                                            </tr>
                                            <tr className="">
                                                <td className='text-[12px] text-[#000] font-[600]  w-[92px]'>Address</td>
                                                <td className="text-[#77787B]">{party.address}</td>
                                            </tr>
                                            <tr className="">
                                                <td className='text-[12px] text-[#000] font-[600] align-top w-[92px]'>Notes</td>
                                                <td className="text-[#77787B]">{party.notes}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}

                    </div>
                    <div className="flex flex-col gap-[24px]">
                        <div className="flex flex-row gap-[10px]">
                            <div className='w-[24px] h-[24px] rounded-[100px] bg-[#F2F2F2] flex'>
                                <p className="text-[#001613] text-[14px] font-[500] mx-auto my-auto">3</p>
                            </div>
                            <p className="font-[600] text-[18px] leading-[25.2px] text-[#000000]">Other Details</p>
                        </div>
                        {fileSelected &&
                            <div className="flex flex-col gap-[16px]">
                                <p className="font-[600] text-[16px] leading-[20.83px] text-[#000000]">Attached Reference</p>
                                <div className="flex flex-row gap-[12px] max:w-[748px] border-[#D1D1D1] border rounded-[12px]  w-[429px]">
                                    <div className="flex border-0 border-r-2 border-r-[#000] px-[20px] py-[16px] rounded-l-[12px] rounded-r-0 bg-[#FAFAFA] w-[60px]">
                                        <FileText size={20} className='text-[#264653] mx-auto my-auto' />
                                    </div>
                                    <div className="flex flex-row justify-between   px-0 py-[12px] gap-[16px]">
                                        <div className="flex flex-col  w-[261px]">
                                            <p className="text-[14px]   my-auto leading-[19.6px] font-[600] w-[241px] truncate text-[#302F2F]">{fileSelected.name} <br />
                                                <span className="text-[12px] font-[400] text-[#949494] leading-[16.8px]">{fileSelected.size}</span>
                                            </p>
                                            {/* <p className="text-[14px] leading-[19.6px] font-[400] text-[#686868]">2.5 MB</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="flex flex-col gap-[16px]">
                            <div className="flex flex-col gap-[12px] w-[656px]">
                                <p className="font-[600] text-[16px] leading-[20.83px] text-[#000000]">Additional Notes</p>
                                <p className="font-[400] p-[16px] text-[14px] leading-[18.23px] border-[#E4E4E4] border rounded-[8px]">{description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Modal>

    );
};

export default SavedDataModalComponent;