import React, { createContext, useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMessage, markQuestionAnswered, setAwaitingResponse, setChatInputMessage, setQuestions, setUpdatingQuestion, setupNewThread, skipFollowupQuestions } from './pages/chatpage/messageSlice';
import { updateMessagingDisabled } from './pages/chatpage/chatSlice';
import { useAuth } from '@clerk/clerk-react';
import { insertNewThread, updateThreadTitleLocal } from './pages/chatpage/threadsSlice';
import applicationStore from "./persistence/stores/RootStore.js"
// Create a WebSocket context
const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const dispatch = useDispatch();
    const url = process.env.REACT_APP_BACKEND_BASE_ENDPOINT;
    const [isConnected, setIsConnected] = useState(false);
    const [accesstoken, setAccesstoken] = useState(null);
    const { getToken } = useAuth();

    useEffect(() => {
        const getMyToken = async () => {
            const token = await getToken({ template: "Backend" });
            return token;
        }
        const getTokenValue = async () => {
            const token = await getMyToken();
            if (token && typeof token === "string") {
                setAccesstoken(token);
            }
        }
        getTokenValue()

    }, [getToken])

    const socketRef = useRef(null);  // Store the WebSocket instance

    const threadData = useSelector(state => state.messageSlice.threadData);
    const newThreadMessage = useSelector(state => state.messageSlice.newThreadMessage);
    const threadMessages = useSelector(state => state.messageSlice.threadData.messages);

    useEffect(() => {
        // Create and store the WebSocket instance
        if (!accesstoken) {
            return;
        }
        const socket = new WebSocket(`${url}/ws?token=${accesstoken}`);
        socketRef.current = socket;

        socket.onopen = () => {
            setIsConnected(true);
            console.log(` connected!`);
        };

        socket.onclose = () => {
            setIsConnected(false);
            console.log(` discconnected!`);
        };
        socket.onmessage = (event) => {
            var message = event.data;
            // setMessages((prev) => [...prev, message]);
            console.log(event);
            if (event.data === "Connection successful!") { return }
            try {
                message = JSON.parse(event.data);
            }
            catch (e) {
                console.log("Error parsing JSON: ", e);
                return;
            }
            switch (message.action) {
                case "ai_title_available":
                    dispatch(updateThreadTitleLocal(message.payload))
                    break;

                case "ask/thread-created":
                    console.log(window.location.pathname)
                    if (window.location.pathname === "/ask") {
                        dispatch(setupNewThread({ threadId: message.payload.thread_id, threadType: "ask", messageId: message.payload.message_id }));
                        dispatch(updateMessagingDisabled(message.payload.messaging_disabled));
                        dispatch(insertNewThread({
                            "thread_id": message.payload.thread_id, "thread_type": "ask",
                            "messaging_disabled": message.payload.messaging_disabled
                        }));
                    }
                    console.log("Thread created: ", event.data);
                    break;
                case "ask/thread-deleted":
                    console.log("Thread deleted: ", event.data);
                    break;
                case "ask/message-added":
                    var payload = message.payload;
                    var threadData = applicationStore.getState().messageSlice.threadData;
                    var chatInputMessage = applicationStore.getState().messageSlice.chatInputMessage;
                    if (threadData && threadData.id === payload.thread_id) {
                        payload["source"] = "user";
                        payload["message_text"] = chatInputMessage;
                        dispatch(addMessage(payload))
                        dispatch(setChatInputMessage(""));
                    }
                    dispatch(setAwaitingResponse(true));
                    // dispatch(updateMessagingDisabled(message.payload.messaging_disabled));
                    break;
                case "ask/new-message-available":
                    var payload = message.payload;
                    var threadData = applicationStore.getState().messageSlice.threadData;
                    if (threadData && threadData.id === payload.thread_id) {
                        dispatch(addMessage(payload))
                    }
                    dispatch(setAwaitingResponse(false));
                    break;
                case "draft/inferred-metadata":
                    console.log("Inferred metadata: ", event.data);
                    // dispatch(updateMessagingDisabled(message.payload.messaging_disabled));
                    break;
                case "draft/thread-created":
                    var selectedDocumentType = applicationStore.getState().homeslice.selectedDocumentType
                    var draftMessage = `Draft a ${selectedDocumentType.name}.
${selectedDocumentType.description}`
                    dispatch(setupNewThread({ threadId: message.payload.thread_id, threadType: "draft", messageId: message.payload.message_id, "message": draftMessage }));
                    dispatch(updateMessagingDisabled(message.payload.messaging_disabled));
                    dispatch(insertNewThread({
                        "thread_id": message.payload.thread_id, "thread_type": "draft",
                        "messaging_disabled": message.payload.messaging_disabled
                    }));
                    break;
                case "draft/set-questions":
                    console.log("Set questions: ", event.data);

                    var payload = message.payload
                    var activeThreadData = applicationStore.getState().messageSlice.threadData;
                    if (activeThreadData && activeThreadData.id === payload.thread_id) {
                        dispatch(setQuestions(payload.questions))
                    }
                    dispatch(updateMessagingDisabled(false));
                    break;
                case "draft/message-added":
                    console.log("Message added: ", event.data);
                    var payload = message.payload;
                    var threadData = applicationStore.getState().messageSlice.threadData;
                    var chatInputMessage = applicationStore.getState().messageSlice.chatInputMessage;
                    if (threadData && threadData.id === payload.thread_id) {
                        payload["source"] = "user";
                        payload["message_text"] = chatInputMessage;
                        dispatch(addMessage(payload))
                        dispatch(setChatInputMessage(""));
                    }
                    break;
                case "draft/new-message-available":
                    console.log("New message available: ", event.data);
                    var payload = message.payload;
                    var threadData = applicationStore.getState().messageSlice.threadData;
                    if (threadData && threadData.id === payload.thread_id) {
                        dispatch(addMessage(payload))
                    }

                    break;
                case "draft/question-updated":
                    console.log("question updated: ", event.data);
                    var updatingQuestion = applicationStore.getState().messageSlice.updatingQuestion;
                    if (updatingQuestion.id === message.payload.question_id) {
                        if (updatingQuestion.action === "skip") {
                            dispatch(skipFollowupQuestions(updatingQuestion.activeIndex))
                        }
                        else if (updatingQuestion.action === "answer") {
                            dispatch(markQuestionAnswered({ index: updatingQuestion.activeIndex, response: updatingQuestion.response }))
                        }
                        dispatch(setUpdatingQuestion({}));
                    }
                    break;
                case "draft/document-created":
                    console.log("Document created: ", event.data);
                    // dispatch(updateMessagingDisabled(message.payload.messaging_disabled));
                    break;
                case "draft/document-deleted":
                    console.log("Document deleted: ", event.data);
                    break;
                case "ai-thread-title-available":
                    console.log("AI thread title available: ", event.data);
                    break;
                default:
                    console.log("Unknown message: ", event.data);
                    break;
            }
        };
        // Cleanup on component unmount
        return () => {
            socket.close();
        };
    }, [url, accesstoken]);

    // Function to send a message through WebSocket
    const sendMessage = (message) => {
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(JSON.stringify(message));
        }
        dispatch(setAwaitingResponse(true));
    };

    const createMessage = (threadType, api, payload) => {
        let r = (Math.random() + 1).toString(36).substring(7);
        return {
            "action": `${threadType}/${api}`,
            "payload": payload,
            "trigger_ws_message_id": r
        };
    }

    // Handle incoming messages (optional)
    const [messages, setMessages] = useState([]);

    return (
        <WebSocketContext.Provider
            value={{
                isConnected,
                messages,
                sendMessage,
                createMessage
            }}
        >
            {children}
        </WebSocketContext.Provider>
    );
};

// Hook to use WebSocket context in any component
export const useWebSocketContext = () => useContext(WebSocketContext);
