import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import {
  annualLegalEXpenses,
  // companyExistance,
  entityOptions,
  // industryArray,
  // legalWorkOption,
  // legalWorkTypePeriod,
  legalWorkTypes,
  numberOfEmployees,
  stateOptions,
} from "../../components/Constants";


const Account = ({ user }) => {
  const [addtionalSettings, setAddtionalSettings] = useState(false);
  const [savingDataStatus, setSavingDataStatus] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [formData, setFormData] = useState({
    full_name: "",
    // lastName: "",
    organization_name: "",
    business_purpose: "",
    entity_type: "",
    email: "",
    business_age: "",
    num_employees: "",
    legal_sources: [],
    legal_work_frequency: "",
    legal_work_type: [],
    annual_legal_spend: "",
    education: "",
    industry: "",
    business_state: "",
    state_incorporation: "",
    phone: "",
  });
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [profileUpdationError, setProfileUpdationError] = useState(false);
  const formDataSaveStatus = useSelector((state) => state.onboarding.status);
  const dispatch = useDispatch();

  // useValidatePageAccess();



  // get user details on page load
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  // const { user } = useUser();
  // debugger;


  useEffect(() => {
    if (user && user.firstName) {
      setFormData({
        full_name: user.firstName + " " + user.lastName,
        // lastName: user.last_name,
        organization_name: user.unsafeMetadata.organization_name,
        // industry: user?.industry ? user?.industry : "",
        email: user?.primaryEmailAddress?.emailAddress,
        state: user?.unsafeMetadata?.state ?? "",
        phone: user?.primaryPhoneNumber?.phoneNumber ? user?.primaryPhoneNumber?.phoneNumber : "",
        age_of_business: user?.unsafeMetadata?.age_of_business,
        num_employees: user?.unsafeMetadata?.num_employees,
        current_legal_source: user?.unsafeMetadata?.current_legal_source ? user?.unsafeMetadata?.current_legal_source.split(", ") : "",
        legal_work_frequency: user?.unsafeMetadata?.legal_work_frequency,
        legal_work_type: user?.unsafeMetadata?.legal_work_type ? user?.unsafeMetadata?.legal_work_type.split(", ") : "",
        annual_legal_spend: user?.unsafeMetadata?.annual_legal_spend,
        business_purpose: user?.unsafeMetadata?.business_purpose,
        entity_type: user?.unsafeMetadata?.entity_type ?? null,
        state_incorporation: user?.unsafeMetadata?.state_incorporation ?? null,
      });
    }
  }, [user]);

  function handleToggleOption(stateKey, value) {
    if (stateKey === "current_legal_source" || stateKey === "legal_work_type") {
      setFormData((prevData) => {
        const currentOptions = prevData[stateKey];

        if (currentOptions.includes(value)) {
          return {
            ...prevData,
            [stateKey]: currentOptions.filter((selectedValue) => selectedValue !== value),
          };
        } else {
          return {
            ...prevData,
            [stateKey]: [...currentOptions, value],
          };
        }
      });
    } else {
      setFormData((prevData) => ({ ...prevData, [stateKey]: value }));
    }
  }

  useEffect(() => {
    const spaceIndex = formData.full_name.indexOf(" ");
    if (spaceIndex !== -1) {
      const firstName = formData.full_name.slice(0, spaceIndex);
      const lastName = formData.full_name.slice(spaceIndex + 1);
      setFirstName(firstName);
      setLastName(lastName);
    } else {
      // If there's no space or input is empty, set the entire name as the first name
      setFirstName(formData.full_name);
      setLastName(""); // Reset last name
    }
  }, [formData.full_name]);

  const handleSend = async () => {
    setShowLoading(true)
    setProfileUpdated(false)
    setProfileUpdationError(false);
    const requiredFields = ["full_name", "organization_name", "business_purpose", "state", "state_incorporation", "entity_type"];
    const isFormValid = requiredFields.every((field) => formData[field]);

    if (isFormValid) {
      // All required fields are filled
      // Add your logic here
    } else {
      setProfileUpdationError(true);
      setShowLoading(false)
      return
    }
    user.update({
      unsafeMetadata: {
        organization_name: formData?.organization_name,
        industry: formData?.industry,
        state: formData?.state,
        phone_number: formData?.phone,
        age_of_business: formData?.age_of_business,
        num_employees: formData?.num_employees,
        current_legal_source: formData?.legal_sources?.length ? formData?.current_legal_source.join(", ") : "",
        legal_work_frequency: formData?.legal_frequency,
        legal_work_type: formData?.legal_work_type?.length ? formData?.legal_work_type.join(", ") : "",
        annual_legal_spend: formData?.annual_legal_spend,
        state_incorporation: formData?.state_incorporation,
        entity_type: formData?.entity_type,
        business_purpose: formData?.business_purpose,
      },
      "firstName": firstName,
      "lastName": lastName,
    })

    setProfileUpdated(true)
    setTimeout(() => {
      setProfileUpdated(false)
    }, 3000)

    const data = {
      email: formData?.email,
      first_name: firstName,
      last_name: lastName,
      organization_name: formData?.organization_name,
      industry: formData?.industry,
      state: formData?.state,
      phone_number: formData?.phone,
      age_of_business: formData?.age_of_business,
      num_employees: formData?.num_employees,
      current_legal_source: formData?.current_legal_source.length ? formData?.current_legal_source.join(", ") : "",
      legal_work_frequency: formData?.legalFrequency,
      legal_work_type: formData?.legal_work_type.length ? formData?.legal_work_type.join(", ") : "",
      annual_legal_spend: formData?.annual_legal_spend,
      state_incorporation: formData?.state_incorporation,
      entity_type: formData?.entity_type,
      business_purpose: formData?.business_purpose,
    };
    console.log(data)
    // dispatch(saveUserProfile(data));
  };

  useEffect(() => {
    if (savingDataStatus === "success" || savingDataStatus === "failed") {
      setShowLoading(false);
    }
  }, [savingDataStatus]);



  useEffect(() => {
    setSavingDataStatus(formDataSaveStatus);
  }, [formDataSaveStatus]);



  return (
    <div className="overflow-y-scroll w-full">
      <div className="max-w-screen-lg flex flex-col items-start gap-6 w-full">
        <div className="account-layout__content flex flex-col w-full bg-white items-start h-full mb-[200px] pt-0 pb-[58px] overflow-x-hidden  ">


          {renderInfoItem("Full Name", formData.full_name, "full_name")}
          {renderInfoItem("Company Name", formData.organization_name, "organization_name")}
          {renderInfoItem("Purpose of your business", formData.business_purpose, "business_purpose")}
          {renderInfoItem("Primary State Of Business", formData.state, "state", "small")}
          {renderInfoItem("State Of Incorporation", formData.state_incorporation, "state_incorporation", "small")}
          {renderInfoItem("Entity Structure", formData.entity_type, "entity_type", 'small')}


          {profileUpdated ? <p className="text-[#5e59ff] text-[18px] font-[700] px-[40px]">Profile Updated successfully!</p> : ""}
          {profileUpdationError ? <p className="text-[#ff5959] text-[18px] font-[700] px-[40px]">Please fill all the required fields</p> : ""}
          <div className={`account-layout__content__item__container px-[10px] ${addtionalSettings ? 'mt-[30px]' : ""}`}>
            <div className="account-layout__content__item">

              <Button
                disabled={showLoading}
                className="bg-black text-white rounded-[8px] px-[24px] h-[54px]"
                onClick={() => {
                  setShowLoading(true);
                  window.analytics.track("update-account-info", { user_email: user?.primaryEmailAddress });
                  handleSend();
                }}
              >
                {/* {showLoading ? <Spinner size={16} className="animate-spin text-black" /> : "Save"} */}
                Save
              </Button>
            </div>
            <p class="text-[12px] px-[35px]">Fields marked with * are mandatory</p>
          </div>

        </div>
      </div>
    </div>
    // </ChatLayout>
  );

  function renderInfoItem(label, value, field, size = "large") {
    return (
      <div className={`account-layout__content__item__container px-[10px]`}>
        <div className="account-layout__content__item">
          <div className="account-layout__content__item__info flex flex-col gap-[14px] items-start">
            <div className="account-layout__content__item__label">{label}<sup>*</sup></div>
          </div>

        </div>
        {renderEditContainer(field, label, value, size)}
      </div>
    );
  }

  function renderEditContainer(field, label, value, size) {
    return (
      <div className={`account-layout__content__edit-container flex !flex-col ${size === "small" ? "w-[50%]" : ""}`}>
        {field === "state" || field === "state_incorporation" || field === "entity_type" ? (
          <div className={`custom-select`}>
            <select
              id={
                field === "state"
                  ? "state"
                  : field === "state_incorporation"
                    ? "state_incorporation"
                    : "entity_type"
              }
              className="form-input"
              name={field}
              value={formData[field]}
              onChange={(e) => handleToggleOption(field, e.target.value)}
            >
              <option value="" disabled>
                {field === "state" || field === "state_incorporation" ? "Select State" : "Select Entity"}
              </option>
              {field === "state" || field === "state_incorporation"
                ? stateOptions.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))
                :
                <>
                  {Object.keys(entityOptions).map((key, index) => (
                    <option key={key} value={entityOptions[key]}>
                      {entityOptions[key]}
                    </option>
                  ))}
                </>}
            </select>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
              <path d="M1 1L6 6L11 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
        ) : (
          <input
            type={field === "email" ? "email" : "text"}
            className="account-layout__content__edit-container__input"
            value={formData[field]}
            placeholder={`Update ${field === "email" ? "Email" : label}`}
            aria-label={field === "email" ? "Email" : field}
            onChange={(e) => handleToggleOption(field, e.target.value)}
          />
        )}

      </div>
    );
  }

  function renderAdditionalSettingsForm() {
    return (
      <form className="company-form max-w-[616px]">
        {/* {renderFormComponent("How long has company existed?", "company-existance", companyExistance, "businessAge")} */}
        {renderFormComponent("How many employees does it have?", "", numberOfEmployees, "num_employees")}
        {/* {renderFormComponent(
          "How does your company handle it’s legal work? Select all that apply.",
          "",
          legalWorkOption,
          "legalSources"
        )} */}
        {/* {renderFormComponent(
          "How frequently does your company have legal needs?",
          "",
          legalWorkTypePeriod,
          "legalFrequency"
        )} */}
        {renderFormComponent("What are your legal needs? Select all that apply.", "", legalWorkTypes, "legal_work_type")}
        {renderFormComponent("How much do you spend annually on legal?", "", annualLegalEXpenses, "annual_legal_spend")}

      </form>
    );
  }

  function renderFormComponent(label, id, options, stateKey) {
    return (
      <div className="company-form-component">
        <label htmlFor={id} className="form-label">
          {label}
        </label>
        <div className="company-form-options">
          {options.map((option, index) => (
            <button
              key={index}
              // className={`option-value ${
              //   (formData[stateKey] === option.value || formData[stateKey].includes(option.value)) && "selected"
              // }`}
              className={`option-value ${stateKey === "current_legal_source" || stateKey === "legal_work_type"
                ? formData[stateKey].includes(option.value)
                  ? "selected"
                  : ""
                : formData[stateKey] === option.value
                  ? "selected"
                  : ""
                }`}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOption(stateKey, option.value);
              }}
              id={`${stateKey}-${option.value}-button`}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>
    );
  }
};

export default Account;
