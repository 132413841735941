import React from 'react'
import SubscriptionCard from './SubscriptionCard';

const PlanCards = (props) => {

    const {
        wrapperClassName,
        cards = []
    } = props;

    return (
        <>
            <div className={`flex gap-6 ${wrapperClassName}`}>

                {
                    cards.map(cardProps => (
                        <SubscriptionCard {...cardProps} />
                    ))
                }

            </div>

            <p className='mt-6 text-xs text-center'>Subscribing provides a dedicated lawyer tailored to your needs. All legal tasks reviewed by our lawyers are guaranteed to be 100% accurate and reliable</p>

        </>
    )
}

export default PlanCards