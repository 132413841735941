import { ArrowLeft, ArrowRight, FileText, PencilSimpleLine, Plus, Trash } from '@phosphor-icons/react';
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react';

const FinalTouchesScreen = (props) => {
    const { backToSelectPartyScreen2, fileSelected, setFileSelected, createDraft } = props;
    const handleFileChange = (event) => {
        setFileSelected(event.target.files[0]);
    };
    function formatFileSize(bytes) {
        if (bytes === 0) return "0 Bytes";
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i];
    }

    const placeholderText = `Describe anything specific you need me to include in your legal document. 
Your notes will help the AI gather more context and enhance it's knowledge base.`;


    return (

        <>
            <div className="flex flex-col gap-[18px] py-[18px] w-full  mx-auto">
                <div className="flex flex-col w-[860px] h-[277px] mx-auto gap-[32px] px-[56px]">
                    {/* <h3><span className="text-[18px] leading-[23.44px] font-[600] text-[#302F2F]">Do you have a reference document? </span>
                        <span className="text-[#686868] text-[14px] leading-[21px] font-[400]">(optional)</span><br />
                        <span className="text-[14px] leading-[19.6p] text-[#686868]">AI uses your reference document for key information, but drafts the document from scratch</span>
                    </h3>
                    {fileSelected ?
                        <div className="flex flex-row gap-[12px] max:w-[748px] border-[#D1D1D1] border rounded-[12px]  w-[429px]">
                            <div className="flex border-0 border-r-2 border-r-[#000] px-[20px] py-[16px] rounded-l-[12px] rounded-r-0 bg-[#FAFAFA] w-[60px]">
                                <FileText size={20} className='text-[#264653] mx-auto my-auto' />
                            </div>
                            <div className="flex flex-row justify-between   px-0 py-[12px] gap-[16px]">
                                <div className="flex flex-col  w-[261px]">
                                    <p className="text-[14px]   my-auto leading-[19.6px] font-[600] w-[241px] truncate text-[#302F2F]">{fileSelected.name} <br />
                                        <span className="text-[12px] font-[400] text-[#949494] leading-[16.8px]">{formatFileSize(fileSelected.size)}</span>
                                    </p>
                                </div>
                                <PencilSimpleLine size={16} className='text-[#B6B6B6] my-auto cursor-pointer' onClick={() => { alert("edit file") }} />
                                <Trash size={16} className='text-[#A20009] my-auto cursor-pointer' onClick={() => { setFileSelected(null) }} />
                            </div>
                        </div>
                        : 
                            <label class="flex flex-row justify-between cursor-pointer bg-[#FAFAFA] border-[1px] w-[748px] rounded-[8px] border-[#E4E4E4] px-[16px] h-[53px] py-[12px]">
                                <div className="flex items-center justify-center flex-row gap-[12px] ">
                                    <FileText size={16} className="text-[#13866F]" />
                                    <span>Attach Reference</span>
                                </div>
                                <div className="flex items-center justify-center flex-row gap-[12px] ">
                                    <span>Add</span>
                                    <Plus size={16} className="text-[#18475A]" />
                                    <input type="file" className="w-0 bg-transparent border-0" onChange={handleFileChange} />
                                </div>
                            </label>
                    } */}

                    <h3><span className="text-[18px] leading-[23.44px] font-[600] text-[#302F2F]">Any other notes? </span>
                        <span className="text-[#686868] text-[14px] leading-[21px] font-[400]">(optional)</span><br />
                    </h3>
                    <TextArea autoSize={{ minRows: 3, maxRows: 6 }}
                        className="border focus-within:!border-black rounded-[8px] p-[16px] mt-[-20px]" placeholder={placeholderText} />
                </div>
            </div>
            <div className="flex flex-row justify-between border-[#E4E4E4] border !border-b-0 mt-[70px] mb-[-45px] h-[96px] rounded-[8px] px-[40px] py-[24px]">
                <button className="flex flex-row gap-[8px] items-center justify-center  h-[30px] text-[#302F2F] font-[600] text-[14px] border-r-[#E4E4E4] my-auto py-[12px] px-[24px]"
                    onClick={() => { backToSelectPartyScreen2() }}>
                    <ArrowLeft size={24} className="text-[#302F2F]" />
                    <span>Go Back</span>
                </button>
                <div className="flex flex-row justify-between">
                    {/* <button className="flex flex-row gap-[8px] items-center justify-center h-[30px] text-[#302F2F] border-0 bg-transparent font-[600] text-[14px] py-[12px] px-[24px] my-auto"
                        onClick={createDraft}>
                        <span>Skip and Proceed</span>
                    </button> */}

                    <button className="rounded-[8px] flex flex-row gap-[8px] items-center justify-center  text-white bg-[#302F2F] font-[600] text-[14px] py-[12px] px-[24px] my-auto"
                        onClick={createDraft}>
                        <span>Continue</span>
                        <ArrowRight size={24} className="text-[#fff]" />
                    </button>
                </div>
            </div>
        </>
    );
};

export default FinalTouchesScreen;