import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

const BASE_ENDPOINT = process.env.REACT_APP_BACKEND_BASE_ENDPOINT;

const initialState = {
  isAuthenticated: false,
  user: null,
  userType: null,
  error: null,
  passwordResetStatus: null,
  passwordResetError: null,
  signuperror: null,
  signupStatus: null,
  refreshToken: null,
  accessToken: null,
  infoMissing: false,
  status: "idle",
  firebase_user: null,
  userStatus: "",
  statusGetStatus: "idle",
  signInWithGoogleStatus: "idle",
  requestPilotStatus: "idle",
  requestPilotError: "",
  profileUpdated: "false",
  confirmThePasswordResetStatus: "idle",
  confirmThePasswordResetError: "",
  passwordUpdateStatus: "idle",
  passwordUpdateError: "",
  getUserDetailsStatus: "idle",
  getLawyerInfoStatus: "idle",
  is_lawyer_prospective: true,
  firebase_id: null,
  failedDueTo418: false,
  currentUser: null,
  subscriptionType: "free",
  userImageMapping: {},
  portalSessionUrl: null,
  portalSessionUrlStatus: "idle",
  showSubscriptionStatusRefreshModal: false,
  showSubscriptionModal: false,
  showRetainerModal: false,
  image: "",
  imageLawyer: ""
};

export const loadDataFromLocalStorage = createAsyncThunk(
  "authentication/loadDataFromLocalStorage",
  async (thunkAPI) => {
    return thunkAPI.rejectWithValue();
  }
);

export const getUserDetails = createAsyncThunk("authentication/getUserDetails", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const url =
    data && data.referredCode
      ? `${process.env.REACT_APP_BACKEND_BASE_ENDPOINT}/api/v0/user/get-info?referral-code=${data.referredCode}`
      : `${process.env.REACT_APP_BACKEND_BASE_ENDPOINT}/api/v0/user/get-info`;
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.get(url, { headers: headers });
    return {
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});



export const getPortalSession = createAsyncThunk("authentication/getPortalSession", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const url = `${process.env.REACT_APP_BACKEND_BASE_ENDPOINT}/api/v0/user/get-portal-url`;
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const response = await axios.post(url, {}, { headers: headers });
    return {
      response: response.data,
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const getLawyerInfo = createAsyncThunk("authentication/getLawyerInfo", async (thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const url = `${process.env.REACT_APP_BACKEND_BASE_ENDPOINT}/api/v0/lawyer/get-info`;
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const response = await axios.get(url, { headers: headers });
    return {
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const getUserStatus = createAsyncThunk("authentication/getUserStatus", async (data, thunkAPI) => {
  // const accessToken = localStorage.getItem("accessToken2");
  const url = `${process.env.REACT_APP_BACKEND_BASE_ENDPOINT}/api/v0/user/${data.email}/get-status`;
  // const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const response = await axios.get(url);
    return {
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});


export const refreshSubscriptionStatus = createAsyncThunk("authentication/refreshSubscriptionStatus", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/v0/user/refresh-subscription-plan`;
    const response = await axios.get(url, { headers: headers });
    return {
      response: response.data,
      request: data,
    };
  }
  catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});


const AuthenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    resetAuthError: (state) => {
      state.error = {};
      state.status = "idle";
      state.signuperror = {};
    },
    updateSubscriptionStatus: (state, action) => {
      state.subscriptionType = action.payload;
      state.subscriptionStatusFetch = "success";
    },
    setUserImageMapping: (state, action) => {
      state.userImageMapping = action.payload;
    },
    setImage: (state, action) => {
      state.image = action.payload
    },
    setImageLawyer: (state, action) => {
      state.imageLawyer = action.payload
    },
    resetProfileUpdatedStatus: (state) => {
      state.profileUpdated = "false";
    },
    updateUserState: (state, action) => {
      var user_data = JSON.parse(localStorage.getItem("user"));
      state.user = user_data
      state.profileUpdated = "true";
      state.subscriptionType = user_data?.subscription_type || "free";
    },
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;
    },
    resetPasswordResetStatus: (state) => {
      state.passwordResetStatus = "idle";
      state.passwordResetError = "";
    },
    setShowSubscriptionModal: (state, action) => {
      state.showSubscriptionModal = action.payload;
    },
    setRetainerModalOpen: (state, action) => {
      state.showRetainerModal = action.payload;
    },
    setShowSubscriptionStatusRefreshModal: (state, action) => {
      state.showSubscriptionStatusRefreshModal = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(getUserDetails.fulfilled, (state, action) => {
        var user_type = action.payload.response.user_type;
        var client_details = action.payload.response.client_info;
        var lawyer_info = action.payload.response.lawyer_info;
        const showPopUp = localStorage.getItem("showForm");

        var user_details = {};
        if (user_type === "lawyer") {
          user_details = action.payload.response.lawyer_info;
          if (user_details == null) {
            user_details = {
              first_name: "",
              last_name: "",
              user_id: action.payload.response.user_id,
            };
          }
        } else {
          user_details = action.payload.response.client_info;
          if (user_details == null) {
            user_details = {
              first_name: "",
              last_name: "",
              organization_name: "",
              email: "",
              state: "",
              phone_number: "",
              age_of_business: "",
              num_employees: "",
              current_legal_source: "",
              legal_work_frequency: "",
              legal_work_type: "",
              annual_legal_spend: "",
              user_id: action.payload.response.user_id,
              is_default_input: true
            };
            state.subscriptionType = action.payload?.response?.subscription_type || "free";
          }
          state.subscriptionType = action.payload.response.subscription_type;
        }
        user_details["user_id"] = action.payload.response.user_id;
        user_details["profileImage"] = action.payload.response.profile_picture_url;
        user_details["is_retainer_signed"] = action.payload.response.is_retainer_signed;
        user_details["user_email"] = action.payload.response.user_email;
        user_details["referral_code"] = action.payload.response.referral_code;
        user_details["referral_discount_available"] = action.payload.response.referral_discount_available;
        state.user = {
          ...user_details,
          user_email: action.payload.response.user_email,
          profileImage: action.payload.response.profile_picture_url,
          is_retainer_signed: action.payload.response.is_retainer_signed,
        };
        localStorage.setItem("user", JSON.stringify(user_details));
        localStorage.setItem("client_details", JSON.stringify(client_details));
        localStorage.setItem("lawyer_info", JSON.stringify(lawyer_info));
        localStorage.setItem("user_email", action.payload.response.user_email);
        state.client_details = client_details;
        state.lawyerInfo = lawyer_info;
        // state.email = action.payload.response.user_email;
        var is_lawyer_prospective = action.payload.response.is_prospective_lawyer;
        localStorage.setItem("is_lawyer_prospective", is_lawyer_prospective);
        state.is_lawyer_prospective = is_lawyer_prospective;
        state.clientDetails = client_details;
        state.client_info = action.payload.response.client_info;
        if (!action.payload.response.client_info.organization_name && (showPopUp === null || showPopUp === undefined)) {
          localStorage.setItem("showForm", true);
        }
        state.userType = "client";
        // state.is_retainer_signed = action.payload.response.is_retainer_signed;
        state.getUserDetailsStatus = "success";
        state.failedDueTo418 = false;
      })
      .addCase(getUserDetails.rejected, (state, action, error) => {
        if (action?.payload?.error?.request?.status === 418) {
          state.failedDueTo418 = true;
          state.getUserDetailsStatus = "failed";
          return;
        }
        state.failedDueTo418 = false;
        let user_details = {
          first_name: "",
          last_name: "",
          organization_name: "",
          email: "",
          state: "",
          phone_number: "",
          age_of_business: "",
          num_employees: "",
          current_legal_source: "",
          legal_work_frequency: "",
          legal_work_type: "",
          annual_legal_spend: "",
          user_id: "",
          is_default_input: true
        };
        state.firebase_user = null;
        state.clientDetails = null;
        state.user = null;
        state.userType = "client";
        state.getUserDetailsStatus = "failed";
        state.user = user_details;
        console.log("getUserDetails.rejected");
      })
      .addCase(getUserDetails.pending, (state, action) => {
        state.firebase_user = null;
        state.getUserDetailsStatus = "loading";
      })

      .addCase(getLawyerInfo.fulfilled, (state, action) => {
        var client_details = action.payload.response.client_info;
        var lawyer_info = action.payload.response.lawyer_info;
        var clients = action.payload.response.clients;
        var prospective_client_ids = action.payload.response.prospective_client_ids;

        var user_details = {};
        user_details = action.payload.response.lawyer_info;
        if (user_details == null) {
          user_details = {
            first_name: "",
            last_name: "",
            user_id: action.payload.response.user_id,
          };
        }
        user_details["user_id"] = action.payload.response.user_id;
        state.user = user_details;
        localStorage.setItem("user", JSON.stringify(user_details));
        localStorage.setItem("client_details", JSON.stringify(client_details));
        localStorage.setItem("lawyer_info", JSON.stringify(lawyer_info));
        state.clients = clients;
        state.prospective_client_ids = prospective_client_ids;
        state.lawyerInfo = { ...lawyer_info, profileImage: action.payload.response.profile_picture_url };

        var is_lawyer_prospective = action.payload.response.is_lawyer_prospective;
        localStorage.setItem("is_lawyer_prospective", is_lawyer_prospective);
        state.is_lawyer_prospective = is_lawyer_prospective;
        state.userType = "lawyer";
        state.getLawyerInfoStatus = "success";
      })
      .addCase(getLawyerInfo.rejected, (state, action) => {
        state.firebase_user = null;
        state.getLawyerInfoStatus = "failed";
        state.failedDueTo418 = false;
      })
      .addCase(getLawyerInfo.pending, (state, action) => {
        state.firebase_user = null;
        state.getLawyerInfoStatus = "loading";
      })

      .addCase(refreshSubscriptionStatus.rejected, (state, action) => {
        state.subscriptionStatusFetch = "failed";
      })
      .addCase(refreshSubscriptionStatus.pending, (state, action) => {
        state.subscriptionStatusFetch = "loading";
        // state.subscriptionType = "";
      })
      .addCase(refreshSubscriptionStatus.fulfilled, (state, action) => {
        state.subscriptionStatusFetch = "success";
        state.subscriptionType = action.payload.response.plan;
      })
      .addCase(getPortalSession.fulfilled, (state, action) => {
        window.location.href = action.payload.response.access_url;
        state.portalSessionUrlStatus = "success";
      })
  },
});

export const {
  updateSubscriptionStatus,
  setUserImageMapping,
  setShowSubscriptionModal,
  setRetainerModalOpen,
  setShowSubscriptionStatusRefreshModal,
  setImage,
  setImageLawyer
} =
  AuthenticationSlice.actions;

export default AuthenticationSlice.reducer;
