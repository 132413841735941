import { Dropdown, Space } from "antd";

import IHLogo from "../../../images/i-h-logo.svg";
import IHLogoLarge from "../../../images/lexxa-logo.png"

import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';

import { Provider, useDispatch, useSelector } from "react-redux";
import { getPortalSession } from "../../UserAuthentication/AuthenticationSlice.js";
import { getSavedParties, setViewChatHistory, setWsInputBox, getAllDraftTypes, getEducationMetadata, updateSidenavCollapse } from '../../../pages/Homepage/HomeSlice.js';

import { SignOutButton, useUser } from "@clerk/clerk-react";
import ChatHistory from "./ChatHistory.js";
import WSInputBox from "./WSInputBox.js";
import { useEffect } from "react";
import { getAllThreads } from "../threadsSlice.js";
import { ArrowLineLeft, ArrowLineRight, Briefcase, ChatsCircle, ClockCounterClockwise, FileMagnifyingGlass, House, PenNib, UserCircleGear } from "@phosphor-icons/react";
import { UserProfile, useClerk } from '@clerk/clerk-react';
import Account from "../../account/Account.js";
import applicationStore from "../../../persistence/stores/RootStore.js";
import { createUserAPI, getLawyerHub, getUserMetadata } from "../../onboarding/onboardingSlice.js";


const SideNav = ({ subscriptionType }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sidenavCollapsed = useSelector((state) => state.homeslice.sidenavCollapsed);
    const viewChatHistory = useSelector((state) => state.homeslice.viewChatHistory);
    const draftDocumentModalOpen = useSelector((state) => state.modalSlice.draftDocumentModalOpen)
    const userMetadataFetchStatus = useSelector((state) => state.onboarding.userMetadataFetchStatus);
    const userMetadata = useSelector((state) => state.onboarding.userMetadata);
    const userAlreadyExist = useSelector((state) => state.onboarding.userAlreadyExist);
    const partiesList = useSelector((state) => state.homeslice.partiesList);
    const savedPartiesFetchStatus = useSelector((state) => state.homeslice.savedPartiesFetchStatus);

    const { openUserProfile } = useClerk();
    const getPortalSessionUrl = () => {
        dispatch(getPortalSession());
    }
    useEffect(() => {
        dispatch(getAllThreads());
    }, [window.location.pathname]); // eslint-disable-line

    const changeSidenavCollapse = () => {
        dispatch(updateSidenavCollapse(!sidenavCollapsed));
    }

    useEffect(() => {
        if (userMetadataFetchStatus === "success") {
            // TODO: add condition to check if user has lawyer info or not/ plan check.
            // Lawyhub should only be shown when the user has lawyer_info and subscription_type is not free_tier
            dispatch(getLawyerHub());
        }
    }, [userMetadataFetchStatus]); // eslint-disable-line

    useEffect(() => {
        dispatch(getUserMetadata())
        dispatch(getEducationMetadata())
    }, []); // eslint-disable-line

    useEffect(() => {
        if (userMetadataFetchStatus === "failed") {
            navigate("/onboarding?utm_source=signup")
        }
        else if (userMetadataFetchStatus === "success") {
            dispatch(getAllDraftTypes());
            dispatch(getSavedParties());
        }
    }, [userMetadataFetchStatus]); // eslint-disable-line



    const items = [
        {
            label: "My Account",
            key: "0",
        },
        {
            label: "Logout",
            key: "2",
        },
    ];
    const { user } = useUser();

    const handleChatHistoryClicked = () => {
        dispatch(setViewChatHistory(true));
    }

    const handleWSInputBoxClicked = () => {
        dispatch(setWsInputBox(true));
    }

    const navigateToAsk = () => {
        window.analytics.track("Ask Icon clicked from sidebar", { "current_url": window.location.path, "user_email": user?.primaryEmailAddress?.emailAddress });
        navigate("/ask");
    }

    const openDraftDocumentModal = (source, message) => {
        window.analytics.track(`Draft document from ${source}`, { "user_email": user?.primaryEmailAddress?.emailAddress });
        navigate(`/?open_draft=${message}`)
        // dispatch(setDraftModalDraftType(message))
    }





    return (
        <>
            <div>
                <div
                    className={`side-nav ${sidenavCollapsed === true ? "small" : "large"} h-screen overflow-y-scroll top-0
                flex py-[24px] px-[0px] flex-col justify-between left-0`}
                >
                    <div className="side-nav-threads flex flex-col gap-6 relative align-end">
                        <div className="sidebar-first-section flex flex-col gap-y-[40px]">
                            <ul className="flex flex-col p-[8px] gap-[16px]">
                                <li className="h-[32px] flex flex-col">
                                    {sidenavCollapsed ?
                                        <img src={IHLogo} className="w-[31.6px] h-[36px]" alt="Inhouse" />
                                        :
                                        <img src={IHLogoLarge} className="w-[83px] h-[24px]" alt="Inhouse" />
                                    }
                                </li>
                            </ul>
                            <ul className="flex flex-col p-[8px] gap-[24px]">
                                <li className="" onClick={() => {
                                    window.analytics.track("Home clicked", { "current_url": window.location.path, "user_email": user?.primaryEmailAddress?.emailAddress });
                                    navigate("/")
                                }}>
                                    <House size={16} weight={`${window.location.pathname === "/" ? "fill" : "thin"}`}
                                        className={`${window.location.pathname === "/" ? "text-[#18475A]" : "text-[#949494]"}`} />
                                    <div className={`inner ${window.location.pathname === "/" ? "active" : ""} text-center`}>
                                        <p className="text-[12px] ">Home</p>
                                    </div>
                                </li>
                                <li className="" onClick={navigateToAsk} >
                                    <ChatsCircle size={16} weight={`${window.location.pathname === "/ask" ? "fill" : "thin"}`}
                                        className={`${window.location.pathname === "/ask" ? "text-[#A843AA]" : "text-[#949494]"}`} />
                                    <div className={`inner ${window.location.pathname === "/ask" ? "active" : ""} text-center`}>
                                        <p className="text-[12px] ">Ask</p>
                                    </div>
                                </li>
                                <li className=" " onClick={() => { openDraftDocumentModal("left sidebar") }}>
                                    <PenNib size={16} weight={`${draftDocumentModalOpen ? "fill" : "thin"}`}
                                        className={`${draftDocumentModalOpen ? "text-[#248370]" : "text-[#949494]"}`} />
                                    <div className={`inner ${draftDocumentModalOpen ? "active" : ""} text-center`}>
                                        <p className="text-[12px] ">Draft</p>
                                    </div>
                                </li>
                                <li className="" >
                                    <FileMagnifyingGlass size={16} weight={`${window.location.pathname === "/review" ? "fill" : "thin"}`}
                                        className={`${window.location.pathname === "/review" ? "text-[#DF8A64]" : "text-[#949494]"}`} />
                                    <div className={`inner ${window.Location.pathname === "/review" ? "active" : ""} text-center`}>
                                        <p className="text-[12px] ">Review</p>
                                    </div>
                                </li>
                            </ul>
                            <ul className="flex flex-col px-[8px] gap-[24px] border-t pt-[24px]">
                                <li className="mt-[20px]" onClick={handleChatHistoryClicked}>
                                    <ClockCounterClockwise size={16} weight={`${viewChatHistory ? "fill" : "thin"}`}
                                        className={`${viewChatHistory ? "text-[#18475A]" : "text-[#949494]"}`} />
                                    <div className={`inner ${viewChatHistory ? "active" : ""} text-center`}>
                                        <p className="text-[12px] ">Task History</p>
                                    </div>
                                </li>
                                <li className="" onClick={handleChatHistoryClicked}>
                                    <Briefcase size={16} weight={`${window.location.pathname === "/lawyer-hub" ? "fill" : "thin"}`}
                                        className={`${window.location.pathname === "/lawyer-hub" ? "text-[#18475A]" : "text-[#949494]"}`} />
                                    <div className={`inner ${window.Location.pathname === "/lawyer-hub" ? "active" : ""} text-center`}>
                                        <p className="text-[12px] ">Lawyer Hub </p>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div className="flex flex-col">
                        <ul className="flex flex-col p-[8px] gap-[20px]">
                            {/* <li className="" onClick={handleChatHistoryClicked}>
                                <Question size={16} weight="thin" className="text-[#949494]" />
                                <div className={`inner ${window.Location.pathname === "/lawyer-hub" ? "active" : ""} text-center`}>
                                    <p className="text-[12px] ">Lawyer Hub </p>
                                </div>
                            </li> */}
                            <li className=""><Dropdown
                                overlayStyle={{ zIndex: 40 }}
                                dropdownRender={(menu) => (
                                    <div className="nav-footer-dropdown flex flex-col items-start w-full gap-4 p-4 bg-white rounded-[5px] border">
                                        <ul>
                                            {(["free", null, "Free-Plan-USD-Monthly", "trial_expired", "subscription_expired", "trial"].indexOf(subscriptionType) === -1) ?
                                                <li className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100 leading-[35px] cursor-pointer">
                                                    <span
                                                        type="text"
                                                        className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100"
                                                        id="navigation-account"
                                                        // href={portalSessionUrl} data-cb-type="portal"
                                                        onClick={getPortalSessionUrl}
                                                        target="blank"
                                                    >
                                                        Manage Subscription
                                                    </span>
                                                </li> : ""}
                                            {items.map((item) => (
                                                <>
                                                    {item.label !== "Logout" ? (
                                                        <li onClick={() => {
                                                            if (item.label === "My Account") {
                                                                // openUserProfile(myUserProfile);
                                                                openUserProfile(
                                                                    {
                                                                        customPages: [
                                                                            {
                                                                                label: 'Onboarding',
                                                                                url: '/custom-page-1',
                                                                                mountIcon: (el) => {
                                                                                    // Custom logic to mount an icon
                                                                                    el.innerHTML = '<svg>...</svg>';
                                                                                },
                                                                                unmountIcon: (el) => {
                                                                                    // Custom logic to unmount an icon
                                                                                    if (el) el.innerHTML = '';
                                                                                },
                                                                                mount: (el) => {
                                                                                    // Custom logic to mount the page
                                                                                    ReactDOM.render(
                                                                                        <Provider store={applicationStore}>
                                                                                            {/* <SavedDataPrompt /> */}
                                                                                            <Account user={user} />
                                                                                        </Provider>
                                                                                        , el);
                                                                                    // el.innerHTML = '<div>Custom Page Content</div>';
                                                                                },
                                                                                unmount: (el) => {
                                                                                    // Custom logic to unmount the page
                                                                                    if (el) el.innerHTML = '';
                                                                                },
                                                                            },
                                                                        ]
                                                                    }
                                                                );
                                                            } else {
                                                                navigate("/my-referral");
                                                            }
                                                        }} className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100 leading-[35px]  cursor-pointer">
                                                            <p
                                                                type="text"
                                                                className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100"

                                                                id="navigation-account"
                                                            >
                                                                {item.label}
                                                            </p></li >
                                                    ) : (
                                                        <li className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100 leading-[35px]  cursor-pointer">
                                                            <SignOutButton redirectUrl={process.env.REACT_APP_POST_SIGNOUT_URL} />
                                                        </li>
                                                    )}
                                                </>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                trigger={["click"]}
                                className="hover:cursor-pointer hover:text-[#5e59ff]"
                            >
                                <Space className="flex w-full">
                                    <div className="flex h-[32px] w-[32px] flex-shrink-0 rounded-[4px] overflow-hidden">
                                        <img
                                            src={user?.imageUrl}
                                            alt="profile"
                                            className="flex-shrink-0"
                                            style={{ objectFit: "cover", width: "100%", height: "100%" }}
                                        />
                                    </div>
                                    <div className="inner">
                                        <p className="text-[12px]">{user?.firstName}</p>
                                    </div>
                                </Space>
                            </Dropdown>
                            </li>
                            <li className={`flex ${sidenavCollapsed ? 'mx-auto' : '!justify-end px-[24px]'}`} onClick={changeSidenavCollapse}>
                                <button className="border flex shadow h-[32px] w-[32px] rounded">
                                    {sidenavCollapsed ?
                                        <ArrowLineRight size={16} className="text-[#141414]  mx-auto my-auto" /> :
                                        <ArrowLineLeft size={16} className="text-[#141414]  mx-auto my-auto" />
                                    }
                                </button>
                            </li>
                        </ul>
                    </div>
                </div >
            </div >
            <ChatHistory />
            <WSInputBox />
            {/* <WebsocketRefreshModal /> */}
        </>
    )
}

export default SideNav;