import { Sparkle } from '@phosphor-icons/react';
import React from 'react';
import WhiteRightArrowImage from "../../../../images/icons/arrow-right-white.svg";

const DocumentDetailsScreenComponent = (props) => {
    const {
        documentType,
        // setDocumentType,
        draftTypeSuggestions,
        continueToConcernedParties,
        handleChange,
        isDropdownVisible,
        filteredSuggestions,
        handleSuggestionClick,
        selectedDocumentTypeTile,
        // setSelectedDocumentTypeTile,
        documentSuggestionClicked
    } = props;
    return (
        // <></>
        <>
            <div className="flex flex-col gap-[32px] py-[20px] w-[748px] mx-auto">
                <div className="flex flex-col gap-[16px]">
                    <h3 className="text-[18px] text-[#264653] leading-[23.44px] font-[600]">Choose Document Type</h3>
                    <label className="text-[14px] font-[400] leading-[19.6px] text-[#686868]">Choosing the right document type helps our AI to create a more accurate draft</label>
                    <div className='flex flex-col gap-[8px] relative'>
                        <input
                            type="text"
                            id="document-type-input"
                            className="border border-[#E0E0E0] rounded w-full h-[40px] px-[12px]"
                            placeholder="e.g. Non-Disclosure Agreement"
                            value={documentType.name}
                            onChange={(e) => { handleChange(e); }}
                        />
                        {isDropdownVisible && filteredSuggestions.length > 0 && (
                            <div className="absolute w-full bg-white border border-gray-300 rounded-md shadow-lg mt-[40px] z-10 pb-[10px]">
                                <div className="px-4 py-2 mt-[8px] flex flex-row gap-[12px]">
                                    <Sparkle size={16} className='my-auto text-[#18475A]' />
                                    <span className='text-[#748A8C] text-[14px] font-[600] my-auto'>Suggestions</span>
                                </div>
                                <ul className='max-h-[250px] overflow-y-scroll'>
                                    {filteredSuggestions.map((suggestion, index) => (
                                        <li
                                            key={index}
                                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                            onClick={() => handleSuggestionClick(suggestion)}
                                        >
                                            {suggestion.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex flex-col gap-[20px]">
                    <div className="flex flex-col w-full h-[full] bg-white gap-[24px]">

                        <div className="flex">
                            <p className="text-[16px] leading-[25.6px] font-[600] flex flex-row gap-[8px]">
                                <span className="my-auto"><Sparkle size={24} className="text-[#248370]" /></span>
                                <span className="my-auto text-[#248370] italic font-[Lora]">Suggested for you</span>
                            </p>
                        </div>
                        <div className="grid grid-cols-3 w-[748px] mx-auto gap-[12px]">
                            {draftTypeSuggestions.slice(0, 5).map((suggestion, index) => (
                                <div className={`flex flex-col h-[100%] border px-[16px] py-[24px] rounded-[4px] w-[241.33px] gap-[10px] cursor-pointer ${documentType.id === suggestion.id ? "!border-[#248370] text-[#000] bg-[#ECF5F3]" : "border-[#E4E4E4]"}`}
                                    key={index}
                                    onClick={() => documentSuggestionClicked({ "suggestion": suggestion, "index": index })}
                                >
                                    <p className="text-[#000] text-[14px] leading-[24px] font-[500]">{suggestion.name} </p>
                                    <p className="text-[#686868] text-[12px] leading-[24px] font-[400]">{suggestion.description} </p>
                                </div>
                            ))}
                            <div className="flex flex-col h-[100%] border px-[16px] py-[24px] rounded-[4px] w-[241.33px] gap-[10px] cursor-pointer"

                                onClick={() => { alert("browse all") }}
                            >
                                <p className="text-[#686868] text-[14px] leading-[24px] font-[400]"> Browse All </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button
                className={`${documentType.name ? "bg-[#18475A]" : "bg-[#949494]"}  text-white py-2 px-4 rounded-lg self-end mr-[56px] flex flex-row gap-[10px]`}
                onClick={() => { continueToConcernedParties(); }}
                disabled={!documentType.name}
                id="document-draft-continue-1"
            >
                Continue <img src={WhiteRightArrowImage} className='pt-[4px]' alt="->" />
            </button>
        </>
    );
};

export default DocumentDetailsScreenComponent;