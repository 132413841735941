import React from 'react'
import Modal from '../../components/Modal'
import { Switch } from 'antd'
import { Buildings, Check, OfficeChair, X } from '@phosphor-icons/react'
import Button from './Button'
import './lawyerHub.css';
import PlanCards from './components/PlanCards'
import { useDispatch, useSelector } from 'react-redux'
import { setShowUpgradePlanNudge } from '../onboarding/onboardingSlice'

const plans = [
    {
        isActive: true,
        title: 'Business Plan',
        titleIcon: < OfficeChair className='ml-2' size={14} />,
        billCycle: 'MONTHLY',
        price: '59',
        badge: {
            text: 'Recommended',
            className: '',
            show: true,
        },
        button: {
            text: 'Upgrade to Business'
        },
        features: [
            { text: '10 legal tasks/month' },
            { text: 'Dedicated Lawyer' },
            { text: '1 lawyer review/month' },
            { text: 'Additional Review costs  $49 / each' },
        ]
    },
    {
        isActive: false,
        title: 'Unlimited Plan',
        titleIcon: <Buildings className='ml-2 text-[#DCA218]' size={14} />,
        billCycle: 'MONTHLY',
        price: '159',
        button: {
            text: 'Upgrade to Unlimited Plan',
            secondary: true,
            className: '!font-bold'
        },
        features: [
            { text: 'Unlimited legal tasks ✨', highlight: true },
            { text: 'Dedicated Lawyer' },
            { text: 'Unlimited Legal Reviews ✨', highlight: true }
        ]
    }
]

const PlanSelectionPopup = () => {
    const dispatch = useDispatch()
    const showUpgradePlanNudge = useSelector((state) => state.onboarding.showUpgradePlanNudge);
    const closePopup = () => {
        dispatch(setShowUpgradePlanNudge(false))
    }
    return (
        <Modal
            contentAreaStyles={{ paddingLeft: '40px', paddingRight: '40px' }}
            // title={<h5 className='text-[#011513] text-2xl mt-8 font-bold'>Make the most out of Lawyer Reviews</h5>}
            isOpen={showUpgradePlanNudge}
            bodyStyle={{ paddingBottom: '40px' }}
        >
            {/* Toggle section */}
            <div className="flex flex-row justify-between w-full mb-[40px] ">
                <h5 className='text-[#011513] text-2xl my-auto font-bold'>Make the most out of Lawyer Reviews</h5>
                <X className='my-auto cursor-pointer' onClick={() => { closePopup() }} size={20} />
            </div>
            <div className='flex items-center justify-center plan-selection-switch'>
                <p className='text-xs text-[#686868] font-medium'>Monthly</p> <Switch className='mx-2 scale-90 bg-gray-200'></Switch><p className='text-xs text-[#686868] font-medium'>Annual</p>
                <p
                    style={{
                        background: "linear-gradient(90deg, rgba(220, 162, 24, 0.79) 0%, rgba(184, 135, 20, 0.79) 100%"
                    }}
                    className='px-2 py-[1px] ml-2 text-white text-xs rounded-md font-medium'>20% OFF</p>
            </div>

            {/* Cards wrapper */}
            <PlanCards cards={plans} wrapperClassName='mt-6' />
        </Modal>
    )
}

export default PlanSelectionPopup