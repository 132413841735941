import React, {
    // forwardRef, 
    useEffect,
    // useRef, useState 
} from "react";
// import { useParams, useLocation, Link } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { setShowSubscriptionStatusRefreshModal, setShowSubscriptionModal, getUserStatus } from "../UserAuthentication/AuthenticationSlice";

import { useUser } from "@clerk/clerk-react"

import { useNavigate } from "react-router-dom";

import SideNav from "../chatpage/Navs/SideNav.js"

import useValidatePageAccess from "../../hooks/useValidatePageAccess.js";
import PaymentModal from "../chatpage/Modals/PaymentModal/index.js";



const BaseLayout = ({ children }) => {
    const navigate = useNavigate();
    const { isLoaded, user } = useUser();
    // const childRef = useRef(null);
    useEffect(() => {
        if (user) {
            if (!user?.firstName || !user?.unsafeMetadata?.organization_name) {
                navigate('/onboarding')
            }
        }
    }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

    useValidatePageAccess();

    return (
        <>
            {isLoaded && (
                <>
                    <div className="flex flex-row w-full">
                        <SideNav />
                        {children}
                    </div>
                    <PaymentModal />
                </>
            )}
        </>
    );
};

export default BaseLayout;
