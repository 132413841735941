import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "@clerk/clerk-react"
import BaseLayout from '../layouts/BaseLayout';
import useValidatePageAccess from "../../hooks/useValidatePageAccess";
import TopNav from "./Navs/TopNav";
import ChatBoxComponent from "./components/ChatBoxComponent";
import ReviewShimmer from "../../components/Shimmers/ReviewShimmer";
import DraftShimmer from "../../components/Shimmers/DraftShimmer";
import AskShimmer from "../../components/Shimmers/AskShimmer";
import { useDispatch, useSelector } from "react-redux";
import { renderMessage } from "../../components/DocumentViewer/helpers"
import SavedDataModalComponent from "../../components/Prompts/SavedDataModalComponent";
import { setEmail } from "../Homepage/HomeSlice";
import { getAskThreadMessages, getDraftThreadMessages, setChatInputMessage, setNewThreadMessage } from "./messageSlice";
import { resetChatPage, updateMessagingDisabled } from "./chatSlice";
import { useWebSocketContext } from "../../WebsocketProvider";
import { markThreadRead, resetData } from "./threadsSlice";
import AskToDraftModal from "../../components/chat/Modals/AskToDraftModal";
import DraftDocumentQuestionaireModal from "./Modals/DraftDocumentQuestionaireModal";

const ChatPageComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoaded, isSignedIn, user } = useUser();
    let { threadType, chatId } = useParams();
    const [inputMessage, setInputMessage] = useState('');
    const { isConnected, messages, sendMessage, createMessage } = useWebSocketContext();

    const chatpageRef = useRef(null);

    const getThreadStatus = useSelector(state => state.chat.getThreadStatus);
    const awaitingResponse = useSelector(state => state.messageSlice.awaitingResponse);

    const threadData = useSelector(state => state.messageSlice.threadData);
    const email = useSelector(state => state.homeslice.email);
    const showAskToDraftModal = useSelector((state) => state.messageSlice.showAskToDraftModal);
    const draftDocumentModalOpen = useSelector((state) => state.modalSlice.draftDocumentModalOpen)

    useEffect(() => {
        dispatch(resetChatPage());
        dispatch(resetData());
        if (threadType === "ask") {
            dispatch(getAskThreadMessages(chatId));
        }
        if (threadType === "draft") {
            dispatch(getDraftThreadMessages(chatId));
        }
        // dispatch(getThread(chatId));
    }, [chatId]); // eslint-disable-line


    // if (threadData.id && threadData.id !== chatId) {
    //     alert("Invalid thread data");
    //     navigate('/');
    // }

    useEffect(() => {
        if (getThreadStatus === "succeess") {
            dispatch(markThreadRead(chatId));
        }
    }, [getThreadStatus]); // eslint-disable-line

    useEffect(() => {
        if (user && isLoaded && isSignedIn) {
            if (!user?.firstName || !user?.unsafeMetadata?.organization_name) {
                navigate('/onboarding')
            }
            dispatch(setEmail(user.primaryEmailAddress.emailAddress));
        }
    }, [user, isLoaded, isSignedIn]); // eslint-disable-line

    useValidatePageAccess();

    const onsubmit = () => {
        window.analytics.track(`${threadData.thread_type} thread: additional message`, {
            "user_email": email, "thread_id": chatId, "message": inputMessage
        })
        dispatch(setChatInputMessage(inputMessage))
        var message = createMessage(threadType, "add-message", { "message": inputMessage, "thread_id": chatId });
        sendMessage(message)
        dispatch(updateMessagingDisabled(true));
    }

    return (
        <>
            {isLoaded ? (
                <BaseLayout>
                    <div className="flex flex-col w-screen">
                        <div className="flex justify-between flex-col w-full h-screen">
                            <TopNav page="chat"></TopNav>
                            {/* <p>{JSON.stringify(threadData.messages)}</p> */}
                            <div className="w-[736px] py-[24px] mx-auto overflow-y-auto gap-[12px] justify-end flex-1" id="scrolling_div">
                                {threadData.messages.map((message, index) => (
                                    <div className="">
                                        {renderMessage(message, index, threadData, "full", email, threadType)}
                                    </div>
                                )
                                )}
                                {awaitingResponse && (

                                    <div className="mt-[12px]">

                                        <>{threadType === "review" && <ReviewShimmer />}</>
                                        <>{threadType === "ask" && <AskShimmer />}</>
                                        <>{threadType === "draft" && <DraftShimmer />}</>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col w-full">
                                <ChatBoxComponent
                                    size="full"
                                    inputMessage={inputMessage}
                                    setInputMessage={setInputMessage}
                                    onsubmit={onsubmit}
                                />
                            </div>
                        </div>
                    </div>
                    {showAskToDraftModal && (
                        <AskToDraftModal />
                    )}

                    {draftDocumentModalOpen && (
                        <DraftDocumentQuestionaireModal
                            email={email}
                            ref={chatpageRef} />
                    )}

                </BaseLayout>
            )
                : <>
                    {
                        (isLoaded) ?
                            <div className="w-full h-full flex justify-center items-center">
                                <p className="text-red-500">Failed to load user details. Please refresh the page.</p>
                            </div> :
                            <div className="w-full h-full flex justify-center items-center mx-auto mt-[200px]">
                                {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
                                {/* <div class="rotating-cursor !h-[50px]"></div> */}
                                <p className="text-black text-[40px]">{isLoaded}</p>
                            </div>
                    }
                </>
            }
            <SavedDataModalComponent />
        </>
    );
};

export default ChatPageComponent;