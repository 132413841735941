import { ArrowLeft } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import { entityOptions, stateOptions } from '../../../../components/Constants';
import { useSelector } from 'react-redux';
import { Spinner } from "@phosphor-icons/react";

const AddPartyScreenComponent = (props) => {
    const { backToSelectPartyScreen, addPartyButtonClicked, newParty, updateParty, updatePartyType } = props;

    const [isDisabled, setIsDisabled] = useState(true)
    const createNewPartyStatus = useSelector(state => state.homeslice.createNewPartyStatus);

    useEffect(() => {
        if (newParty.is_entity) {
            if (!newParty.full_name || !newParty.state_incorporation || !newParty.primary_business_state || !newParty.entity_type || !newParty.address) {
                setIsDisabled(true)
            }
            setIsDisabled(false)
        }
        else {
            if (!newParty.full_name || !newParty.primary_business_state || !newParty.address) {
                setIsDisabled(true)
            }
            setIsDisabled(false)
        }
    }, [newParty])

    return (
        // <></>
        <>
            <div className="flex w-[860px] flex-row border-b gap-[12px] py-[20px] px-[41px]">
                <button
                    className=""
                    onClick={() => {
                        backToSelectPartyScreen()
                    }}
                >
                    <ArrowLeft size={24} className=" my-auto" weight="bold" />
                </button>
                <div className='flex flex-col gap-[4px]'>
                    <p className="text-[24px] font-[600] leading-[33.6px] font-[Lora] italic">Add New Party</p>
                    {/* <p className='text-[#686868] text-[14px] leading-[19.6px] font-[400]'>Party details will be used by our AI while drafting the details</p> */}
                </div>
            </div>
            <div className="flex flex-col w-[860px] mx-auto justify-between px-[56px] gap-[4px]">
                <form className="flex flex-col gap-[16px]">
                    <div className="flex flex-col w-full">
                        <label className='text-[18px] font-[600]'>Name of Party</label>
                        <input
                            type="text"
                            className="border border-[#E0E0E0] rounded w-full h-[40px] px-[12px]"
                            placeholder="Party Name"
                            value={newParty.full_name}
                            onChange={(e) => updateParty("full_name", e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col w-full">
                        <p className='text-[16px] font-[600]'>Party Type:</p>
                        <div className="flex flex-row w-full gap-[36px]">
                            <label className='text-[18px] font-[600] gap-[12px] flex flex-row' onClick={(e) => {
                                updatePartyType(false)
                            }}>
                                <input
                                    type="radio"
                                    className="border border-[#E0E0E0] rounded  h-[20px] w-[20px] px-[12px] my-auto "
                                    checked={newParty.has_entity === "no"}
                                />
                                <span className="my-auto">Individual</span>
                            </label>
                            <label className='text-[18px] font-[600] gap-[12px] flex flex-row' onClick={() => { updatePartyType(true) }}>
                                <input
                                    type="radio"
                                    className="border border-[#E0E0E0] rounded h-[20px] w-[20px] px-[12px] my-auto"
                                    checked={newParty.has_entity === "yes"}
                                />
                                <span className="my-auto"> Company</span>
                            </label>
                        </div>
                    </div>
                    <div className="flex flex-row gap-[12px] w-full">
                        <div className="flex flex-col w-full">
                            <label className='text-[18px] font-[600]'>Incorporation state</label>
                            {/* <input
                                type="text"
                                className="border border-[#E0E0E0] rounded w-full h-[40px] px-[12px]"
                                placeholder="State"
                                value={newParty.state_incorporation}
                                onChange={(e) => updateParty("state_incorporation", e.target.value)}
                            /> */}
                            <select
                                id="business-state"
                                className="bg-white border px-[12px] h-[40px]"
                                name="state"
                                value={newParty.state_incorporation}
                                onChange={(e) => updateParty("state_incorporation", e.target.value)}
                            >
                                <option value="" disabled>
                                    Select State
                                </option>
                                {stateOptions.map((state, index) => (
                                    <option key={index} value={state}>
                                        {state}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-col w-full">
                            <label className='text-[18px] font-[600]'>Primary Business State</label>
                            {/* <input
                                    type="text"
                                    className="border border-[#E0E0E0] rounded w-full h-[40px] px-[12px]"
                                    placeholder="Incorporation Type. e.g.: LLC"
                                    value={newParty.state_incorporation}
                                    onChange={(e) => updateParty("state_incorporation", e.target.value)}
                                /> */}
                            <select
                                id="entity-structure"
                                className="bg-white border px-[12px] h-[40px]"
                                name="entity"
                                value={newParty.primary_business_state}
                                onChange={(e) => updateParty("primary_business_state", e.target.value)}
                            >
                                <option value="" disabled>
                                    Select State
                                </option>
                                {stateOptions.map((state, index) => (
                                    <option key={index} value={state}>
                                        {state}
                                    </option>
                                ))}
                            </select>

                        </div>
                        {newParty.is_entity ? (
                            <div className="flex flex-col w-full">
                                <label className='text-[18px] font-[600]'>Incorporation Type</label>
                                {/* <input
                                    type="text"
                                    className="border border-[#E0E0E0] rounded w-full h-[40px] px-[12px]"
                                    placeholder="Incorporation Type. e.g.: LLC"
                                    value={newParty.state_incorporation}
                                    onChange={(e) => updateParty("state_incorporation", e.target.value)}
                                /> */}
                                <select
                                    id="entity-structure"
                                    className="bg-white border px-[12px] h-[40px]"
                                    name="entity"
                                    value={newParty.entity_type}
                                    onChange={(e) => updateParty("entity_type", e.target.value)}
                                >
                                    <option value="" disabled>
                                        Select Entity
                                    </option>
                                    {Object.keys(entityOptions).map((key, index) => (
                                        <option key={key} value={entityOptions[key]}>
                                            {entityOptions[key]}
                                        </option>
                                    ))}
                                </select>

                            </div>
                        ) : <div className="flex flex-col w-full"></div>}
                    </div>

                    <div className="flex flex-col w-full">
                        <label className='text-[18px] font-[600]'>Address</label>
                        <input
                            type="text"
                            className="border border-[#E0E0E0] rounded w-full h-[40px] px-[12px]"
                            placeholder="Address"
                            value={newParty.address}
                            onChange={(e) => updateParty("address", e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col w-full">
                        <label className='text-[18px] font-[600]'>Notes</label>
                        <textarea
                            className="border border-[#E0E0E0] rounded w-full h-[80px] px-[12px] py-[8px]"
                            placeholder="Notes"
                            value={newParty.description}
                            onChange={(e) => updateParty("description", e.target.value)}
                        ></textarea>
                    </div>
                    <button
                        className="bg-[#13866F] text-white py-2 px-4 rounded-lg self-end"
                        disabled={isDisabled}
                        onClick={() => { addPartyButtonClicked() }}
                        type="button"
                    >
                        {createNewPartyStatus === "loading" ? <Spinner size={16} className="animate-spin" /> : "Add Party"}
                    </button>
                </form >
            </div >

        </>
    );
};

export default AddPartyScreenComponent;