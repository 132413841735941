import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { Modal } from "antd";
import Fuse from 'fuse.js';

import { useDispatch, useSelector } from 'react-redux';
// import { X, FileArrowUp, ClockCounterClockwise, FileText, ArrowLeft } from "@phosphor-icons/react";

import { setDraftDocumentModalOpen, setDraftModalDraftType } from '../../../../modalSlice.js';
import { createNewParty, setSelectedDocumentType } from '../../../Homepage/HomeSlice.js';
import ConcernedPartyScreenComponent from './ConcernedPartyScreen.js';
import ChoosePartyScreenComponent from './ChoosePartyScreen.js';
import AddPartyScreenComponent from './AddPartyScreen.js';
import DocumentDetailsScreenComponent from './DocumentDetailsScreen.js';
import DraftDocumentModalHeader from './DraftDocumentModalHeader.js';
import FinalTouchesScreen from './FinalTouchesScreen.js';
import { updateMessagingDisabled } from "../../chatSlice";
import { useWebSocketContext } from "../../../../WebsocketProvider";


const DraftDocumentQuestionaireModal = (props, ref) => {
    // console.log(props)
    const {
        email,
    } = props;

    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const { isConnected, messages, sendMessage, createMessage } = useWebSocketContext();

    const [isDocumentDetailsScreen, setIsDocumentDetailsScreen] = useState(true);
    const [concernedPartiesScreen, setConcernedPartiesScreen] = useState(false);
    const [documentCreationMode, setDocumentCreationMode] = useState(true);
    const [choosePartyScreenOpen, setchoosePartyScreenOpen] = useState(false);
    const [addPartyScreenOpen, setAddPartyScreenOpen] = useState(false);
    const [parties, setParties] = useState([])
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [finishingTouchesScreen, setFinishingTouchesScreen] = useState(false);
    const [selectedDocumentTypeTile, setSelectedDocumentTypeTile] = useState("");
    const [whichPartyIam, setWhichPartyIam] = useState("Primary Party");
    const [fileSelected, setFileSelected] = useState(null);
    const [newParty, setNewParty] = useState(
        {
            "is_primary": false,
            "is_entity": false,
            "has_entity": "no",
            "entity_type": null,
            "full_name": "",
            "state_incorporation": "California",
            "primary_business_state": "California",
            "address": "",
            "description": ""
        }
    );
    const [searchParty, setSearchParty] = useState("");

    const draftDocumentModalOpen = useSelector((state) => state.modalSlice.draftDocumentModalOpen)
    const draftTypeSuggestions = useSelector((state) => state.homeslice.draftTypes);
    const partiesList = useSelector((state) => state.homeslice.partiesList);
    const selectedParties = useSelector((state) => state.homeslice.selectedParties);
    const documentType = useSelector((state) => state.modalSlice.draftModalDraftType);
    const selectedDocumentType = useSelector((state) => state.homeslice.selectedDocumentType);
    const createNewPartyStatus = useSelector((state) => state.homeslice.createNewPartyStatus);

    useEffect(() => {
        setIsDocumentDetailsScreen(true);
        setConcernedPartiesScreen(false);
        setDocumentCreationMode(true);
        setchoosePartyScreenOpen(false);
        setAddPartyScreenOpen(false);
        setParties([]);
        setFilteredSuggestions([]);
        setIsDropdownVisible(false);
        setFinishingTouchesScreen(false);
        setSelectedDocumentTypeTile("");
        setWhichPartyIam("Primary Party");
        setFileSelected(null);
        setSearchParty("");
        resetParty();
    }, [draftDocumentModalOpen])

    const setDocumentType = (userInput) => {
        dispatch(setDraftModalDraftType(userInput))
    }

    const handleChange = (e) => {
        const userInput = e.target.value;
        setDocumentType({
            "id": null,
            "name": userInput,
            "category": "",
            "draft_type": "custom",
            "description": "",
            "min_parties": 1,
            "max_parties": 2,
            "party_tags": [
                "Party 1",
                "Party 2"
            ]
        },);

        if (userInput === "") {
            setFilteredSuggestions([]);
            setIsDropdownVisible(false);
            return;
        }
        const fuseOptions = {
            keys: ['category', 'description', 'name'],
            threshold: 0.4  // Adjust the threshold to control the fuzzy matching sensitivity
        };

        const fuse = new Fuse(draftTypeSuggestions, fuseOptions);
        const searchText = userInput
        const results = fuse.search(searchText);
        const filtered = results.map(result => result.item);

        setFilteredSuggestions(filtered);
        setIsDropdownVisible(userInput.length > 0);
        setSelectedDocumentTypeTile();
        dispatch(setSelectedDocumentType(documentType));
    };

    const handleSuggestionClick = (suggestion) => {
        setDocumentType(suggestion);
        setIsDropdownVisible(false);
        setSelectedDocumentTypeTile();
    };

    const resetParty = () => {
        setNewParty({
            "is_primary": false,
            "is_entity": false,
            "has_entity": "no",
            "entity_type": null,
            "full_name": "",
            "state_incorporation": "California",
            "primary_business_state": "California",
            "address": "",
            "description": ""
        });
    }

    const documentSuggestionClicked = (data) => {
        window.analytics.track("Document Type Suggestions Clicked", {
            "user_email": email,
            "title": data.suggestion.display_prompt
        })
        setDocumentType(data.suggestion);
        setSelectedDocumentTypeTile(data);
        dispatch(setSelectedDocumentType(documentType));
    }

    const continueToConcernedParties = () => {
        setIsDocumentDetailsScreen(false);
        setConcernedPartiesScreen(true);
        dispatch(setSelectedDocumentType(documentType));
        window.analytics.track("Document Type Selected", {
            user_email: email, "next": "Concerned parties screen"
        })
    }

    const addNewPartyButtonClicked = () => {
        window.analytics.track("Add Details button --> New party Screen", {
            user_email: email
        });
        setConcernedPartiesScreen(false);
        setDocumentCreationMode(false);
        setSearchParty("");
        setchoosePartyScreenOpen(true);
    }

    const backtoScreen1 = () => {
        window.analytics.track("Concerned Parties Screen -> Document Type Selection screen", { user_email: email })
        setConcernedPartiesScreen(false);
        setDocumentCreationMode(true);
        setIsDocumentDetailsScreen(true);
    }

    const backToConcernedPartyScreen = () => {
        window.analytics.track("Select Party Screen -> Concerned Parties Screen", { user_email: email })
        setchoosePartyScreenOpen(false);
        setDocumentCreationMode(true);
        setConcernedPartiesScreen(true);
    }

    const addNewPartyTileClicked = () => {
        window.analytics.track("Add New Party Tile Clicked", { user_email: email })
        setchoosePartyScreenOpen(false);
        setSearchParty("");
        resetParty();
        setAddPartyScreenOpen(true);
    }

    const backToSelectPartyScreen = () => {
        setAddPartyScreenOpen(false);
        setchoosePartyScreenOpen(true);
        window.analytics.track("moving back to choose party screen", { user_email: email })
    }

    const addPartyButtonClicked = () => {
        addParty();
        window.analytics.track("Add Party Button Clicked", { user_email: email, "party": newParty })
    }

    useEffect(() => {
        if (createNewPartyStatus === "success") {
            setAddPartyScreenOpen(false);
            setchoosePartyScreenOpen(true);
        }
    }, [createNewPartyStatus]) // eslint-disable-line react-hooks/exhaustive-deps


    const createDraftContinueButtonClicked = () => {
        if (selectedParties.length >= documentType.min_parties && selectedParties.length <= documentType.max_parties) {
            window.analytics.track("concerned parties finalised", { user_email: email, "next": "/draft/id page" })
            setConcernedPartiesScreen(false);
            setDocumentCreationMode(false);
            setFinishingTouchesScreen(true);
        }
    }

    const closeModal = () => {
        window.analytics.track("Draft Document Modal Closed", { user_email: email })
        dispatch(setDraftDocumentModalOpen(false))
    }

    const backToSelectPartyScreen2 = () => {
        setFinishingTouchesScreen(false);
        setDocumentCreationMode(true);
        setConcernedPartiesScreen(true);
        window.analytics.track("moving back to choose party screen", { user_email: email })
    }


    useEffect(() => {
        setParties(partiesList)
    }, [partiesList])

    useEffect(() => {
        filterParties(searchParty)
    }, [searchParty]); // eslint-disable-line react-hooks/exhaustive-deps

    const filterParties = (searchParty) => {
        setParties(partiesList.filter(party => party.name?.toLowerCase().includes(searchParty?.toLowerCase())));
    }

    const updatePartyType = (yes) => {
        if (!yes) {
            setNewParty({ ...newParty, "is_entity": false, "entity_type": "Individual", "has_entity": "no" })
        }
        else {
            setNewParty({ ...newParty, "is_entity": true, "has_entity": "yes" })
        }
    }

    const updateParty = (key, value) => {
        setNewParty({ ...newParty, [key]: value })
    }

    const addParty = () => {
        var entityInfo = null
        var individualInfo = null
        if (newParty.is_entity) {
            entityInfo = {
                "type": newParty.entity_type,
                "state_incorporation": newParty.state_incorporation,
                "primary_business_state": newParty.primary_business_state,
                "address": newParty.address,
                "business_purpose": newParty.description,
            }
        }
        else {
            individualInfo = {
                "state_residence": newParty.state_incorporation,
                "description": newParty.description,
                "address": newParty.address
            }
        }

        const data = {
            "is_primary": false,
            "full_name": newParty.full_name,
            "entity_info": entityInfo,
            "individual_info": individualInfo
        }

        dispatch(createNewParty(data))
        window.analytics.track("New Party added", { user_email: email, "party": data })
    }


    const confirmPartySelectionClicked = () => {
        window.analytics.track("Confirm Party Selections", { user_email: email })
        setchoosePartyScreenOpen(false);
        setDocumentCreationMode(true)
        setConcernedPartiesScreen(true)
    }

    const createDraft = () => {
        window.analytics.track("Create Draft Button Clicked", { user_email: email })
        var primaryParty = selectedParties.filter(party => party.is_primary === true);
        var otherParties = selectedParties.filter(party => party.is_primary !== true)
        var parties = {};
        var nonUserPartyTag = selectedDocumentType.party_tags.filter(tag => tag !== whichPartyIam)[0];
        otherParties.forEach((party, index) => {
            parties[party.id] = nonUserPartyTag;
        })
        // var otherParties = selectedParties.filter(party => party.is_primary !== true).map(party => party.id);
        var draftId = null;
        var documentName = null;
        if (selectedDocumentType.id) {
            draftId = selectedDocumentType.id;
        }
        else {
            documentName = selectedDocumentType.name
        }


        var message = createMessage("draft", "create-thread", {
            "draft_type_id": draftId,
            "new_document_name": documentName,
            "description": documentType.description,
            "reference_doc_id": null,
            "parties_involved": parties,
            "primary_party_id": primaryParty[0].id,
            "reference_thread_id": null
        })
        sendMessage(message)
        dispatch(updateMessagingDisabled(true));
    }

    useImperativeHandle(ref, () => ({
        isDocumentDetailsScreen,
        concernedPartiesScreen,
        documentType,
        documentCreationMode,
        choosePartyScreenOpen,
        addPartyScreenOpen,
        addParty,
        parties,
        newParty,
        searchParty,
        draftDocumentModalOpen,
        partiesList,
        selectedParties,
        confirmPartySelectionClicked
    }));

    return (
        <Modal
            open={draftDocumentModalOpen}
            className="draft-document-popup rounded overflow-hidden w-[860px]"
            cancelButtonProps={{
                style: { display: 'none' }
            }}
        >
            <div className="w-[860px] h-full flex flex-col  gap-[24px]">
                {(documentCreationMode || finishingTouchesScreen) && (<>
                    <DraftDocumentModalHeader
                        isDocumentDetailsScreen={isDocumentDetailsScreen}
                        concernedPartiesScreen={concernedPartiesScreen}
                        finishingTouchesScreen={finishingTouchesScreen}
                        closeModal={closeModal}
                    />

                    {isDocumentDetailsScreen && (
                        <DocumentDetailsScreenComponent
                            documentType={documentType}
                            setDocumentType={setDocumentType}
                            handleChange={handleChange}
                            isDropdownVisible={isDropdownVisible}
                            filteredSuggestions={filteredSuggestions}
                            handleSuggestionClick={handleSuggestionClick}
                            draftTypeSuggestions={draftTypeSuggestions}
                            selectedDocumentTypeTile={selectedDocumentTypeTile}
                            setSelectedDocumentTypeTile={setSelectedDocumentTypeTile}
                            continueToConcernedParties={continueToConcernedParties}
                            documentSuggestionClicked={documentSuggestionClicked}
                        />
                    )}
                    {concernedPartiesScreen && (
                        <ConcernedPartyScreenComponent
                            backtoScreen1={backtoScreen1}
                            createDraftContinueButtonClicked={createDraftContinueButtonClicked}
                            addNewPartyButtonClicked={addNewPartyButtonClicked}
                            whichPartyIam={whichPartyIam}
                            setWhichPartyIam={setWhichPartyIam}
                        />
                    )}
                    {finishingTouchesScreen && (<>
                        <FinalTouchesScreen setFinishingTouchesScreen={setFinishingTouchesScreen}
                            backToSelectPartyScreen2={backToSelectPartyScreen2}
                            fileSelected={fileSelected}
                            setFileSelected={setFileSelected}
                            createDraft={createDraft}
                        />
                    </>)}
                </>)}




                {choosePartyScreenOpen && (
                    <ChoosePartyScreenComponent
                        backToConcernedPartyScreen={backToConcernedPartyScreen}
                        addNewPartyTileClicked={addNewPartyTileClicked}
                        confirmPartySelectionClicked={confirmPartySelectionClicked}
                        parties={parties}
                        searchParty={searchParty}
                        setSearchParty={setSearchParty}
                        filterParties={filterParties}
                    />
                )}

                {addPartyScreenOpen && (
                    <AddPartyScreenComponent
                        backToSelectPartyScreen={backToSelectPartyScreen}
                        addPartyButtonClicked={addPartyButtonClicked}
                        newParty={newParty}
                        updateParty={updateParty}
                        updatePartyType={updatePartyType}
                    />
                )}
            </div>
        </Modal >
    )
    // return (
    //     <div className="w-[1200px] h-[600px] overflow-scroll">
    //         <iframe src={iframeUrl} width="100%" height="800" ref={iframeRef} />
    //     </div>
    // )
}

export default forwardRef(DraftDocumentQuestionaireModal);