import { ArrowUpRight, CalendarCheck, User } from '@phosphor-icons/react'
import React from 'react'
import Button from '../Button'

const LawyerMatching = () => {

    return (
        <div className='pt-12'>

            <div className='flex items-center justify-center'>
                <div className='px-3 py-3 bg-gray-200 rounded-full w-fit'>
                    <User className='self-center' size={24} />
                </div>
            </div>

            <p className='px-6 mt-4 text-[#011513] font-semibold text-lg text-center'>Your Dedicated Lawyer</p>
            <p className='px-6 mt-1 text-sm font-normal text-[#353535] text-center'>You’ve started the lawyer matching process. <br />Lawyer’s details will be displayed here once assigned.</p>

            <hr className='px-6 mt-10 text-gray-300 ' />

            <div className='px-6 py-10'>
                <h6 className='text-sm font-semibold text-[#353535]'>Upcoming</h6>
                <ul className='relative mt-6'>
                    {/* dashed line */}
                    <div className='absolute top-0 h-[90%] left-[19px] w-2 border-l-[2px]  border-dotted'></div>
                    {/* Solid line */}
                    <div className='absolute z-0 top-0 h-[50%] left-[19px] border-solid  border-l-[2px]  w-2'></div>
                    {/* Items */}
                    <li className='flex gap-3'>
                        <div className='bg-white w-fit h-fit z-[100] rounded-full'>
                            <div className='z-[100] bg-[#137513]/10 flex-none flex justify-center items-center !w-10 !h-10 rounded-full'>
                                <CalendarCheck className='text-[#137513]' size={20} />
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            {/* <h6 className='text-[#011513] font-bold text-sm'>Confirmation of Lawyer Match</h6> */}
                            <p className='text-sm'>1-on-1 with our legal support team on<span className='font-semibold'> 24 Oct @10:00PM</span></p>
                            <Button
                                className={'w-fit py-2 mt-2 px-3 text-sm'}
                                text={<>View Meeting Details <ArrowUpRight className='ml-2' size={20} />

                                </>} />
                        </div>
                    </li>
                    <li className='flex gap-3 mt-12'>
                        <div className='bg-white w-fit h-fit z-[100] rounded-full'>

                            <div className='z-10 bg-[#18475A]/10 flex-none flex justify-center items-center !w-10 !h-10 rounded-full'>
                                <CalendarCheck className='text-[#18475A]' size={20} />

                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <h6 className='text-[#011513] font-bold text-sm'>Confirmation of Lawyer Match</h6>
                            <p className='mt-1 text-sm'>1-on-1 with our legal support team on<span className='font-semibold'> 24 Oct @10:00PM</span></p>
                        </div>
                    </li>
                    <li className='flex gap-3 mt-12'>
                        <div className='bg-white w-fit h-fit z-[100] rounded-full'>
                            <div className='z-10 bg-[#949494]/10 flex-none flex justify-center items-center !w-10 !h-10 rounded-full'>
                                <CalendarCheck className='text-[#949494]' size={20} />

                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <h6 className='text-[#011513] font-medium text-sm'>Lawyer gets matched</h6>
                            <p className='mt-1 text-sm text-[#686868]'>1-on-1 with our legal support team on<span className='font-semibold'> 24 Oct @10:00PM</span></p>
                        </div>
                    </li>
                </ul>
            </div>




        </div>
    )
}

export default LawyerMatching