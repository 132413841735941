import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const FollowupInputPrompt = ({ index, answers, setAnswers, activeIndex }) => {
    const [inputValue, setInputValue] = React.useState(answers[index]);
    useEffect(() => {
        setAnswers({ ...answers, [index]: inputValue });
    }, [inputValue]); // eslint-disable-line
    const questionsanswered = useSelector(state => state.messageSlice.threadData.questions.answers);
    const skipped = useSelector(state => state.messageSlice.threadData.questions.skipped);

    return (
        <div className=''>
            <input type="text"
                placeholder="Type your answer"
                className="w-[660px] border border-collapse border-[#E0E0E0] cursor-pointer rounded-[8px] px-[14px] py-[12px] ml-[30px]"
                value={inputValue}
                disabled={Object.keys(questionsanswered).indexOf(index) !== -1 || skipped.indexOf(index) !== -1 || index < activeIndex}
                onChange={(e) => { setInputValue(e.target.value) }}
            />
        </div>
    );
};

export default FollowupInputPrompt;