import { ArrowLeft, Briefcase, ChatsCircle, FileMagnifyingGlass, PenNib, X, City, Wallet, Handshake, Receipt } from '@phosphor-icons/react';
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
// import { allSuggestions } from '../../../../pages/Homepage/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { setDraftModalDraftType, setShowExploreMoreModal } from '../../../../modalSlice';
import { useNavigate } from 'react-router-dom';

const ExploreMoreModalComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [filteredResults, setFilteredResults] = useState([])
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedType, setSelectedType] = useState("All");
    const showExploreMoreModal = useSelector(state => state.modalSlice.showExploreMoreModal);
    const email = useSelector(state => state.homeslice.email);
    const allSuggestions = useSelector(state => state.homeslice.allSuggestions);

    const getFilteredResults = () => {
        var results = []
        var ask_examples = allSuggestions?.ask_examples || []
        var draft_examples = allSuggestions?.draft_examples || []
        ask_examples = ask_examples.map((item) => ({ ...item, category: "ask" }))
        draft_examples = draft_examples.map((item) => ({ ...item, category: "draft" }))
        if (selectedType === "All") {
            if (allSuggestions) {
                results = ask_examples.concat(draft_examples)
            }
        }
        else if (selectedType === "Ask") {
            if (allSuggestions) {
                results = ask_examples
            }
        }
        else if (selectedType === "Draft") {
            if (allSuggestions) {
                results = draft_examples
            }
        }
        if (selectedCategory) {
            results = results.filter((item) => item.category_id === selectedCategory)
        }
        return results
    }

    useEffect(() => {
        setFilteredResults(getFilteredResults())
    }, [selectedCategory, selectedType, allSuggestions])

    const closeModal = () => {
        window.analytics.track("explore more modal closed", { "user_email": email })
        dispatch(setShowExploreMoreModal(false));
    }
    const getIcon = (type, bold = false) => {
        var realType = `${type}${bold ? "-bold" : ""}`
        switch (realType) {
            case "ask":
                return (
                    <ChatsCircle size={16} className="text-[#C61DCA]" />
                )

            case "draft":
                return (
                    <PenNib size={16} className="text-[#13866F]" />
                );
            case "review":
                return (
                    <FileMagnifyingGlass size={16} className="text-[#E79571]" />
                );
            case "briefcase":
                return (<Briefcase size={20} className="text-[#18475A]" />)

            case "briefcase-bold":
                return <Briefcase size={20} className="text-[#18475A]" weight='bold' />

            case "city":
                return <City size={20} className="text-[#18475A]" />
            case "city-bold":
                return <City size={20} className="text-[#18475A]" weight="bold" />
            case "wallet":
                return <Wallet size={20} className="text-[#18475A]" />
            case "wallet-bold":
                return <Wallet size={20} className="text-[#18475A]" weight='bold' />
            case "handshake":
                return <Handshake size={20} className="text-[#18475A]" />
            case "handshake-bold":
                return (<Handshake size={20} className="text-[#18475A]" weight='bold' />)
            case "receipt":
                return (<Receipt size={20} className="text-[#18475A]" />)
            case "receipt-bold":
                return (<Receipt size={20} className="text-[#18475A]" weight='bold' />)
            default:
                return "#C61DCA";
        }
    }
    const openDraftDocumentModal = (source, message) => {
        window.analytics.track(`Draft document from ${source}`, { "user_email": email });
        dispatch(setDraftModalDraftType(message))
    }

    const openAskPage = (message) => {
        const queryParams = new URLSearchParams({ key: 'value' });
        navigate("/ask", { state: { message: message } });
    }

    const goToSomePlace = (suggestion, suggestionType) => {
        switch (suggestionType) {
            case "ask":
                openAskPage(suggestion.actual_prompt);
                closeModal();
                break
            case "draft":
                openDraftDocumentModal("home page tiles", suggestion.actual_prompt);
                closeModal();
                break
            case "review":
                openDraftDocumentModal();
                closeModal();
                break
            default:
                return
        }
    }

    return (
        <Modal
            open={showExploreMoreModal}
            className="explore-more-popup rounded overflow-hidden w-[992px]"
            footer={null}
            cancelButtonProps={{
                style: { display: 'none' }
            }}
        >
            <div className="w-[992px] h-[614px] flex flex-col  gap-[24px]">
                <div className="explore-more-modal">
                    <div className="flex w-[992px] flex-col border-b gap-4 py-[20px] px-[16px]">
                        <div className='flex gap-[8px] w-full justify-between'>
                            <h1 className="text-[20px] font-[600] leading-[28px] font-[Lora] italic flex flex-row gap-[16px]">
                                <ArrowLeft size={24} className="my-auto" />
                                <span className='my-auto'>Discover More</span>
                            </h1>
                            <X
                                size={24}
                                className="my-auto"
                                color="#343330"
                                onClick={() => closeModal()}
                                id="cancel-draft-document-button"
                            />
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="flex w-[200px] flex-col gap-[12px] overflow-y-scroll">
                            {allSuggestions?.categories?.map((key) => (
                                // <div key={key.id} className="suggestion-item py-[12px] px-[32px] w-[178px]">
                                <div className="flex flex-row gap-[12px] w-[178px] px-[32px] py-[12px] cursor-pointer" onClick={() => { setSelectedCategory(key.id) }}>
                                    <span className="my-auto">{getIcon(key.icon_name, selectedCategory === key.id ? true : false)}</span>
                                    <span className={`my-auto flex text-[14px] leading-[21px] ${selectedCategory === key.id ? 'font-[600]' : 'font-[400]'}`}>{key.name}</span>
                                </div>
                                // </div>
                            ))}
                        </div>
                        <div className='flex flex-col gap-[12px] w-[812px] bg-[#FAFAFA]'>
                            <div className='px-[24px] flex flex-row gap-[8px] h-[50px]'>
                                <button className={`border rounded-[12px] px-[24px] h-[30px] my-auto text-[12px] leading-[14px] ${selectedType === "All" ? 'font-[600] text-black !border-[#000]' : "font-[400]"} text-[#686868]`}
                                    onClick={() => setSelectedType("All")}>
                                    All
                                </button>
                                <button className={`border rounded-[12px] px-[24px] h-[30px] my-auto text-[12px] leading-[14px] ${selectedType === "Ask" ? 'font-[600] text-black !border-black' : "font-[400]"} text-[#686868]`}
                                    onClick={() => setSelectedType("Ask")}>
                                    Ask
                                </button>
                                <button className={`border rounded-[12px] px-[24px] h-[30px] my-auto text-[12px] leading-[14px] ${selectedType === "Draft" ? 'font-[600] text-black !border-black' : "font-[400]"} text-[#686868]`}
                                    onClick={() => setSelectedType("Draft")}>
                                    Draft
                                </button>
                            </div>

                            <div className="grid grid-cols-3 gap-[12px] px-[24px] pt-[0px] pb-[32px] overflow-y-scroll h-[480px] rounded-[20px] auto-rows-auto">
                                {/* {["ask", "draft"].map((exampleType) => (
                                    <>
                                        {allSuggestions[exampleType + "_examples"]?.map((item) => ( */}
                                {filteredResults.map((item) => (
                                    <>
                                        <div key={item} className="suggestion-item-list-item border border-[#E4E4E4] flex flex-col p-[16px] bg-white w-[244px] h-[150px] cursor-pointer"
                                            onClick={() => { goToSomePlace(item, item.category) }}>
                                            <h3 className="w-full text-[14px] font-[400] leading-[21px] mx-auto text-[#686868] flex flex-col gap-[16px]">
                                                <span className="my-auto">{getIcon(item.category)}</span>
                                                <span className="my-auto">{item.display_prompt}</span>
                                            </h3>
                                        </div>
                                    </>
                                ))}
                                {/* </>))} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal >
    );
};

export default ExploreMoreModalComponent;