import { ArrowLeft, Briefcase, Plus, User } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import WhiteRightArrowImage from "../../../../images/icons/arrow-right-white.svg";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedParties } from '../../../Homepage/HomeSlice';

const ChoosePartyScreenComponent = (props) => {
    const dispatch = useDispatch();
    const { backToConcernedPartyScreen, confirmPartySelectionClicked, addNewPartyTileClicked } = props;
    const [parties, setParties] = useState([]);
    const partiesList = useSelector(state => state.homeslice.partiesList);
    const selectedParties = useSelector(state => state.homeslice.selectedParties);
    const [localselectedParties, setlocalselectedParties] = useState(selectedParties);

    const selectedDocumentType = useSelector((state) => state.homeslice.selectedDocumentType);

    useEffect(() => {
        if (selectedParties.length === 0) {
            var primaryParty = partiesList.filter(party => party.is_primary === true);
            dispatch(setSelectedParties(primaryParty));
            setlocalselectedParties(primaryParty);
        }
    }, [selectedParties]);

    const manageSelectedParties = (party) => {
        // now that we are limiting local parties to 1, we can just toggle the party
        // if (localselectedParties.includes(party)) {
        //     setlocalselectedParties(localselectedParties.filter(p => p !== party));
        // } else {
        //     setlocalselectedParties([...localselectedParties, party]);
        // }
        if (localselectedParties[0] === party) {
            setlocalselectedParties([]);
        }
        else {
            setlocalselectedParties([party]);
        }
    }

    const partiesConfirmed = () => {
        var primaryParty = partiesList.filter(party => party.is_primary === true);
        var localParties = localselectedParties.filter(party => party.is_primary !== true);
        var allParties = [...localParties, ...primaryParty];

        dispatch(setSelectedParties(allParties));
        confirmPartySelectionClicked();
    }
    const getInvolvedPartyCountError = () => {
        if (selectedDocumentType.min_parties === selectedDocumentType.max_parties) {
            return `Please ensure that number of selected parties should be ${selectedDocumentType.min_parties - 1}`
        } else {
            return `Please ensure that number of selected parties should be between ${selectedDocumentType.min_parties - 1} and ${selectedDocumentType.max_parties - 1}`
        }
    }

    const getState = (party) => {
        if (!party.individual_info) {
            return party?.entity_info?.state_incorporation;
        }
        else {
            return party?.individual_info?.state_residence
        }
    }

    const getAddress = (party) => {
        if (!party.individual_info) {
            return party?.entity_info?.address;
        }
        else {
            return party?.individual_info?.address;
        }
    }

    const getDescription = (party) => {
        if (!party.individual_info) {
            return party?.entity_info?.business_purpose;
        }
        else {
            return party?.individual_info?.description;
        }
    }

    useEffect(() => {
        if (partiesList.length > 0) {
            var nonPrimaryParties = partiesList.filter(party => party.is_primary !== true);
            setParties(nonPrimaryParties);
        }
    }, [partiesList]);
    return (
        <>
            <div className="flex w-[860px] flex-row border-b gap-[12px] py-[20px] px-[41px]">
                <button
                    className=""
                    onClick={() => { backToConcernedPartyScreen() }}
                >
                    <ArrowLeft size={24} className=" my-auto" weight="bold" />
                </button>
                <div className='flex flex-col gap-[4px]'>
                    <p className="text-[24px] font-[600] leading-[33.6px] font-[Lora] italic">Choose Party</p>
                    <p className='text-[#686868] text-[14px] leading-[19.6px] font-[400]'>Party details will be used by our AI while drafting the details</p>
                </div>
            </div>

            <div className="flex flex-col w-[860px] mx-auto justify-between gap-[4px] px-[56px] py-[24px]">
                {/* <p>{JSON.stringify(selectedDocumentType)}</p>
                <p>{localselectedParties.length}</p>
                <p>{JSON.stringify(localselectedParties)}</p> */}
                <div className="grid grid-cols-2 gap-[12px] items-stretch">
                    <div className="bg-[#FAFAFA] rounded-[12px] border cursor-pointer border-[#E8E8E8] flex flex-col justify-center gap-[8px] pt-[56px] pb-[48px] px-[16px]"
                        onClick={() => { addNewPartyTileClicked(); }}>
                        <Plus size={32} className='text-[#7C7B79] mx-auto' />
                        <p className='text-[18px] leading-[23.44px] font-[700] text-center'>Add New</p>
                    </div>
                    {parties.map((party, index) => (
                        <div className={`flex flex-col gap-[16px] border p-[18px] rounded-[8px] cursor-pointer ${localselectedParties.indexOf(party) !== -1 ? "bg-[#ECF5F34D] border-[#248370]" : ""} `}
                            onClick={() => { manageSelectedParties(party) }}>
                            <div className='flex justify-between w-full mx-auto'>
                                <div className='flex flex-col gap-[8px]'>
                                    <p className='text-[16px] font-[600] text-[#141414]'>{party.full_name}</p>
                                </div>
                                <div className={`flex flex-row gap-[8px] h-[24px] p-[4px] bg-[${party.entity_info !== null ? "#DCA218" : "#13866F14"}]`}
                                >
                                    {!party.individual_info && (
                                        <Briefcase size={16} className="text-[#DCA218] my-auto" />
                                    )
                                    }
                                    {!party.entity_info && (
                                        <User size={16} className="text-[#DCA218] my-auto" />
                                    )
                                    }
                                    <p className='text-[12px] font-[500] text-[#686868] my-auto capitalize'>{party.individual_info ? "Individual" : "Company"}</p>

                                </div>
                            </div>
                            <div className="border-t h-[1px] w-full"></div>
                            <div className="overflow-y-scroll">
                                <table className="gap-y-[8px] flex flex-col">
                                    <tbody className='flex flex-col gap-y-[8px]'>
                                        <tr className="gap-[12px]">
                                            <td className='text-[12px] text-[#000] font-[600] w-[92px]'>State</td>
                                            <td className="text-[#77787B]">{getState(party)}</td>
                                        </tr>
                                        <tr className="">
                                            <td className='text-[12px] text-[#000] font-[600]  w-[92px]'>Address</td>
                                            <td className="text-[#77787B]">{getAddress(party)}</td>
                                        </tr>
                                        <tr className="">
                                            <td className='text-[12px] text-[#000] font-[600] align-top w-[92px]'>Notes</td>
                                            <td className="text-[#77787B]">{getDescription(party)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ))}

                </div>
                {parties.length > 0 &&
                    <div className="flex flex-col justify-between w-[100%] mx-auto mt-[20px]">

                        {/* {(localselectedParties.length < (selectedDocumentType.min_parties - 1) || localselectedParties.length > (selectedDocumentType.max_parties - 1)) &&
                            <p className='mt-[20px] text-center w-full text-red-500'>{getInvolvedPartyCountError()}</p>
                        } */}
                        {localselectedParties.length === 0 && (
                            <p className='mt-[20px] text-center w-full text-red-500'>Please select a party to continue</p>
                        )}
                        <div className="flex flex-row justify-between w-[100%] mx-auto mt-[20px]">
                            <span></span>
                            <button
                                className={`bg-[#18475A] disabled:bg-[#949494] text-white gap-[12px] rounded-[8px] flex flex-row px-[24px] py-[12px]`}
                                id="document-draft-continue-2"
                                onClick={() => {
                                    partiesConfirmed()
                                }}
                                // disabled={(localselectedParties.length < (selectedDocumentType.min_parties - 1) || localselectedParties.length > (selectedDocumentType.max_parties - 1))}
                                disabled={localselectedParties.length === 0}
                            >
                                Confirm Selection <img src={WhiteRightArrowImage} className='pt-[4px]' alt="->" />
                            </button>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default ChoosePartyScreenComponent;