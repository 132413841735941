import { Briefcase, ChatsCircle, Lightning, ShareFat } from '@phosphor-icons/react';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const TopNav = ({ page }) => {
    const location = useLocation();
    const [chatId, setChatId] = useState('');
    const userMetadata = useSelector((state) => state.onboarding.userMetadata);


    useEffect(() => {
        // Check if the page URL changes
        // If yes, update the variable called chatId
        setChatId(location.pathname);
    }, [location]);
    const currentDate = new Date();
    const options = { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = currentDate.toLocaleDateString('en-US', options);


    const showBanner = () => {
        // {
        //     "subscription_type": "free_tier",
        //         "lawyer_status": "not_matched",
        //             "lawyer_info": null,
        //                 "show_new_lawyer_assigned_notification": false,
        //                     "legal_review_credits_total": 0,
        //                         "legal_review_credits_used": 0,
        //                             "threads_total": 3,
        //                                 "threads_remaining": 3
        // }
        switch (userMetadata?.subscription_type) {
            case "":
            case null:
            case undefined:
                return <></>
            case "free_tier":
                return <>
                    <button className='px-[12px] py-[12px] w-[187px] text-black border rounded bg-white flex flex-row gap-[8px]'>
                        <span className="my-auto text-[14px] leading-[19.6px]">Upgrade to Premium </span>
                        <span className="my-auto"><Lightning size={16} className="my-auto text-[#DCA218]" /> </span>
                    </button>
                </>;
            case "subscriber_business":
                var numTotal = userMetadata?.threads_total;
                var numRemaining = userMetadata?.threads_remaining;
                var dark = Math.round((numRemaining / numTotal * 100) / 10) * 10;
                if (numRemaining === 0) {
                    dark = 72;
                }
                if (numTotal && numRemaining) {
                    return <div className="flex flex-row gap-[16px]">
                        <div className="flex flex-col gap-[8px] my-auto">
                            <span className="my-auto text-[14px] leading-[19.6px]">{numRemaining} tasks left</span>
                            <div className="flex flex-row w-[72px] h-[4px] bg-[#E4E4E4] rounded-[40px]">
                                <span className={`bg-[#18475A99] h-[4px] w-[${dark}px] rounded-[40px]`}></span>
                                {/* <span className={`bg-[#18475ACC] h-[4px] w-[${light}px]`}></span> */}
                            </div>
                        </div>

                        <button className='p-[12px] text-black border !border-[#000] rounded-[8px] bg-[#ECF5F3] flex flex-row gap-[12px]'>
                            <span className="my-auto text-[14px] leading-[19.6px]">Upgrade to Unlimited Plan </span>
                            <span className="my-auto"><Lightning size={16} className="my-auto text-[#18475A]" weight="fill" /> </span>
                        </button>
                    </div>
                }
                return <></>
            case "subscriber_enterprise":
                return <></>
            default:
                return <></>
        }
        // case payment_status = "starter/business/enterprise"
    }

    if (page === "home") {
        return (
            <div className="flex flex-row">
                {/* Your component content goes here */}
                <div className="flex justify-end w-full py-[16px] mx-auto px-[48px] gap-[16px]">
                    {showBanner()}
                </div>
            </div>
        );
    } else if (page === "ask_home" || page === "chat") {
        return (
            <div className="flex flex-row">
                {/* Your component content goes here */}
                <div className="flex justify-between  w-[100%] py-[12px] border-b border-[#E4E4E4] px-[24px] bg-[#FCFCFA]">
                    <h1 className='flex flex-row gap-[8px] my-auto py-auto'>
                        <span><ChatsCircle size={16} className="text-[#C61DCA]" /></span>
                        <span className='text-[14px] leading-[21px] font-[500] my-auto'>{formattedDate}</span>
                    </h1>
                    {page === "chat" &&
                        <button className="bg-white border w-[40px] h-[40px] rounded-[8px] ">
                            <ShareFat size={13} className="mx-auto text-[#264653]" />
                        </button>
                    }
                </div>
            </div>
        );

    }
    else {
        return (
            <div className="flex flex-row w-full h-full py-auto">
                <h1> Hello world {page}</h1>
            </div>
        )
    }

}

export default TopNav;